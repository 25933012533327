import { useContext, useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import {
    collection,
    getDocs,
    doc,
    getDoc,
    query,
    where
} from "firebase/firestore";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import "../datatable/datatable.scss";
import customLocaleText from '../datatable/LocaleText'
import { userColumns, tiendasColumns } from '../../datatablesource';
import { useNavigate } from 'react-router-dom';

const DataCuadrantes = () => {
    const { currentService, adminData } = useContext(AuthContext);
    const [vigilantes, setVigilantes] = useState([]);
    const [servicios, setServicios] = useState([]);
    const [tipoData, setTipoData] = useState('vigilantes'); // Por defecto, mostrar vigilantes

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (!currentService.id || !adminData || !adminData.tiendas) {
                console.error("Información de servicio o administrador no disponible.");
                return;
            }

            try {
                // Obtener los números de tienda basados en los ID de documentos de tiendas del administrador
                const adminTiendasNumeros = await Promise.all(
                    adminData.tiendas.map(async (tiendaId) => {
                        const tiendaRef = doc(db, "servicios", currentService.id, "tiendas", tiendaId);
                        const tiendaDoc = await getDoc(tiendaRef);
                        return tiendaDoc.exists() ? tiendaDoc.data().numTienda : null;
                    })
                );

                // Filtrar los vigilantes que tienen tiendas coincidentes
                const vigilantesRef = collection(db, "servicios", currentService.id, "vigilantes");
                const vigilantesSnapshot = await getDocs(vigilantesRef);
                const vigilantesList = vigilantesSnapshot.docs
                    .map((doc) => ({ id: doc.id, ...doc.data() }))
                    .filter((vigilante) =>
                        vigilante.tiendas && vigilante.tiendas.some(tienda => adminTiendasNumeros.includes(tienda.numero))
                    );

                // Aplicar la transformación a las tiendas de los vigilantes
                const vigilantesWithFixedTiendas = vigilantesList.map((vigilante) => {
                    if (Array.isArray(vigilante.tiendas)) {
                        return { ...vigilante, tiendas: vigilante.tiendas.map((tienda) => `${tienda.numero}`) };
                    } else {
                        return vigilante;
                    }
                });
                setVigilantes(vigilantesWithFixedTiendas);

                // Filtrar las tiendas basándose en los ID de tiendas asignadas en adminData
                const tiendasIds = adminData.tiendas; // Suponiendo que adminData.tiendas es un array de ID de tiendas
                const tiendasQuery = query(
                    collection(db, "servicios", currentService.id, "tiendas"),
                    where("__name__", "in", tiendasIds)
                );
                const tiendasSnapshot = await getDocs(tiendasQuery);

                const tiendasData = tiendasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setServicios(tiendasData);
            } catch (err) {
                console.error("Error al cargar los datos:", err);
            }
        };

        fetchData();
    }, [currentService, adminData]);

    const handleEditClick = (id) => {
        // Construye la URL de edición correspondiente
        const editUrl = tipoData === 'vigilantes' ? `edit-vigilante/${encodeURIComponent(id)}` : `cuadrantes/edit-servicio/${encodeURIComponent(id)}`;
        navigate(editUrl);
    };

    // Configuración de columnas para DataGridPro
    const columns = tipoData === 'vigilantes' ? userColumns : tiendasColumns;

    const actionColumn = [
        {
            field: "action",
            headerName: "Acción",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        {/* Utiliza Link para navegar a la página de edición */}
                        <div className="editarButton" onClick={() => handleEditClick(params.row.id)}>
                            Editar
                        </div>
                    </div>
                );
            },
        },
    ];

    // Datos que se mostrarán en el DataGridPro
    const dataToShow = tipoData === 'vigilantes' ? vigilantes : servicios;

    return (
        <div>
            {/* Switch para cambiar entre Vigilantes y Servicios */}
            <FormControlLabel
                control={<Switch checked={tipoData === 'servicios'} onChange={() => setTipoData((prev) => (prev === 'vigilantes' ? 'servicios' : 'vigilantes'))} />}
                label={tipoData === 'vigilantes' ? 'Mostrar Servicios' : 'Mostrar Vigilantes'}
            />
            <DataGridPro
                rows={dataToShow}
                columns={columns.concat(actionColumn)}
                pageSize={100}
                rowsPerPageOptions={[9]}
                checkboxSelection
                localeText={customLocaleText}
                slots={{ toolbar: GridToolbar }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
            />
        </div>
    );
};

export default DataCuadrantes;
