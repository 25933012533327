import DataCuadrantes from "../../components/cuadrantes/DataCuadrates";
import "../documents/documents.scss";
import React, { useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

/**
 * Componente de la página de documentos.
 *
 * @returns {JSX.Element} Elemento JSX que representa la página de documentos.
 */
const Cuadrantes = () => {

  /**
 * Realiza un desplazamiento de la página hacia la parte superior al cargar.
 */
  useEffect(() => {
    window.scrollTo(0, 0); // Desplaza la página a la parte superior al cargar
  }, []);

  const { collapsed } = useSidebarState();
  const containerClass = collapsed ? "singleContainer collapsed" : "singleContainer";

  return (
    <div data-testid="sidebar" className="single">
      <Sidebar />
      <div data-testid="navbar" className={containerClass}>
        <Navbar />
        <div data-testid="list" className="bottom">
          <DataCuadrantes />
        </div>
      </div>
    </div>
  );
};

export default Cuadrantes;
