import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export const useAlert = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleCloseAlert = () => {
    // Cerrar la alerta y realizar cualquier otra acción necesaria al cerrar
    setShowAlert(false);
  };

  const showAlertComponent = (severity, title, message) => {
    setShowAlert({
      severity,
      title,
      message,
    });
  };

  const AlertComponent = () => (
    showAlert && (
      <Alert
        onClose={handleCloseAlert}
        severity={showAlert.severity}
        sx={{ width: '100%', mt: 2 }}
      >
        <AlertTitle>{showAlert.title}</AlertTitle>
        {showAlert.message}
      </Alert>
    )
  );

  return [showAlertComponent, AlertComponent];
};
