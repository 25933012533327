import "./sidebar.scss";
import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Link } from "react-router-dom";
import logo from "../../images/logo.png"
import logoCollapsed from "../../images/logoCollapsed.png"
import { useSidebarState } from './SidebarStateContext';

/**
 * Componente de la barra lateral de navegación.
 */
const Sidebar = () => {
  const { collapsed } = useSidebarState();

  const { adminData } = useContext(AuthContext); // Agrega esta línea

  // Función para determinar si el usuario puede ver la pestaña Documentos
  const puedeVerDocumentos = () => {
    if (!adminData || !adminData.tipoUsuario) return false;

    // Verifica si el usuario es administrativo o administrador, y no solo inspector
    return adminData.tipoUsuario.includes('administrativo') || adminData.tipoUsuario.includes('administrador');
  };

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : ''}`}>
      <div className="top">

        <Link to="/" style={{ textDecoration: "none" }}>
          {!collapsed && (
            <span className="logo">
              <img src={logo} alt="logo" />
              SENTRY
            </span>
          )}
          {collapsed && (
            <span className="logoCollapsed">
              <img src={logoCollapsed} alt="logo" />
            </span>
          )}
        </Link>
      </div>

      <hr />
      <div className="center">
        <ul>
          {puedeVerDocumentos() && (
            <Link to="/documentos" style={{ textDecoration: "none" }}>
              <li>
                <DescriptionOutlinedIcon className="icon" />
                <span className="link-text">Documentos</span>
              </li>
            </Link>
          )}
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardOutlinedIcon className="icon" />
              <span className="link-text">Dashboard</span>
            </li>
          </Link>

          <Link to="/vigilantes" style={{ textDecoration: "none" }}>
            <li>
              <BadgeOutlinedIcon className="icon" />
              <span className="link-text">Vigilantes</span>
            </li>
          </Link>
          <Link to="/clientes" style={{ textDecoration: "none" }}>
            <li>
              <GroupsOutlinedIcon className="icon" />
              <span className="link-text">Clientes</span>
            </li>
          </Link>
          {puedeVerDocumentos() && (
            <Link to="/papelera" style={{ textDecoration: "none" }}>
              <li>
                <DeleteOutlineOutlinedIcon className="icon" />
                <span className="link-text">Papelera</span>
              </li>
            </Link>
          )}
          <Link to="/form-table" style={{ textDecoration: "none" }}>
            <li>
              <FileOpenOutlinedIcon className="icon" />
              <span className="link-text">Formularios</span>
            </li>
          </Link>
          <Link to="/notificaciones" style={{ textDecoration: "none" }}>
            <li>
              <NotificationsActiveIcon className="icon" />
              <span className="link-text">Notificaciones</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom">
        <div className="privacy-policy-link">
          <Link to="/privacy-policy">Política de Privacidad</Link>
        </div>
        <div className="privacy-policy-link">
          <a href="https://bepart-innova.gitbook.io/sentry/" alt="guías">Guías de usuario</a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
