import "./update.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import Datatable from "../../components/forms/FormUpdate"
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

/**
 * Componente de la página de lista.
 *
 * @returns {JSX.Element} Elemento JSX que representa la página de lista.
 */
const UpdateForm = () => {
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "singleContainer collapsed" : "singleContainer";

  return (
    <div data-testid="sidebar2" className="single">
      <Sidebar />
      <div data-testid="navbar2" className={containerClass}>
        <Navbar />
        <Datatable />
      </div>
    </div>
  )
}

export default UpdateForm