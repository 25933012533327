import React, { useEffect, useState, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, getDoc, doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Card, CardContent, CardActions, Button, IconButton } from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import "./datatableClientes.scss";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CloseIcon from '@mui/icons-material/Close';
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const DatatableClientes = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [showDeleted, setShowDeleted] = useState(false);
  const [selectedClientInfo, setSelectedClientInfo] = useState(null);

  const { currentUser, currentService, adminData } = useContext(AuthContext);


  useEffect(() => {
    const fetchData = async () => {
      if (!currentService.id || !adminData || !adminData.clientes) {
        console.error("Información de servicio o administrador no disponible.");
        return;
      }

      try {
        const clientesRef = collection(db, "servicios", currentService.id, "clientes");
        const clientesSnapshot = await getDocs(clientesRef);

        const adminClientes = new Set(adminData.clientes); // Convierte el arreglo en un conjunto para una búsqueda más eficiente
        const list = clientesSnapshot.docs
          .filter(doc => adminClientes.has(doc.id)) // Filtra solo los clientes asignados al administrador
          .map(doc => ({ id: doc.id, ...doc.data() }));

        setData(list);
      } catch (error) {
        console.error("Error al obtener los clientes:", error);
      }
    };

    fetchData();

  }, [currentService, adminData]);


  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm(
        "¿Estás seguro de que deseas eliminar este cliente?"
      );
      if (confirmDelete) {
        // Encuentra y actualiza el campo "borrado" en lugar de eliminar el cliente
        const serviciosSnapshot = await getDocs(collection(db, "servicios"));

        for (const servicioDoc of serviciosSnapshot.docs) {
          const clienteRef = doc(servicioDoc.ref, "clientes", id);

          // Verifica si el elemento ya está marcado como borrado
          const clienteDoc = await getDoc(clienteRef);
          if (clienteDoc.exists() && clienteDoc.data().borrado) {
            // Recupera el elemento si ya está borrado
            await setDoc(clienteRef, { borrado: false }, { merge: true });
          } else {
            // Marca como borrado si no está borrado
            await setDoc(clienteRef, { borrado: true }, { merge: true });
          }
        }

        // Actualiza la lista local estableciendo "borrado: true" o mueve el elemento a la lista de recuperados
        setData((prevData) =>
          prevData.map((item) =>
            item.id === id
              ? item.borrado
                ? { ...item, borrado: false }
                : { ...item, borrado: true }
              : item
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };


  const filteredData = useMemo(() => {
    return data.filter((row) => {
      const rowValues = Object.values(row).join("").toLowerCase();
      return rowValues.includes(filter.toLowerCase()) &&
        (showDeleted ? row.borrado : !row.borrado);
    });
  }, [data, filter, showDeleted]);


  const handleMailClick = (cliente) => {
    if (cliente.email) {
      setSelectedClientInfo((prevInfo) => ({
        ...prevInfo,
        [cliente.id]: `Correo electrónico: ${cliente.email}`,
      }));
    }
  };

  const handlePhoneClick = (cliente) => {
    if (cliente.telefono) {
      setSelectedClientInfo((prevInfo) => ({
        ...prevInfo,
        [cliente.id]: `Número de teléfono: ${cliente.telefono}`,
      }));
    }
  };

  const clearSelectedClientInfo = (id) => {
    setSelectedClientInfo((prevInfo) => {
      const newInfo = { ...prevInfo };
      delete newInfo[id];
      return newInfo;
    });
  };

  const renderClienteCard = (cliente) => {
    if (showDeleted || !cliente.borrado) {
      return (
        <Card key={cliente.id} className="clienteCard">
          <CardContent>
            {cliente.img ? (
              <img src={cliente.img} alt="Logo" className="clienteLogo" />
            ) : (
              <StoreMallDirectoryRoundedIcon className="clienteLogo" style={{ color: '#4678c3', fontSize: 40 }} />
            )}
            <div className="clienteInfo">
              <div className="nombreServicio">{cliente.nombre}</div>
              <div className="contactoLogos">
                <IconButton className="iconContainer" onClick={() => handleMailClick(cliente)}>
                  <MailOutlineIcon className="whiteIcon" /> {/* Ícono de mail */}
                </IconButton>
                <IconButton size="small" className="iconContainer" onClick={() => handlePhoneClick(cliente)}>
                  <PhoneEnabledIcon className="whiteIcon" /> {/* Ícono de teléfono */}
                </IconButton>
              </div>
              {cliente.id && selectedClientInfo && selectedClientInfo[cliente.id] && (
                <div className="selectedClientInfo">
                  {selectedClientInfo[cliente.id]}
                  <IconButton size="small" onClick={() => clearSelectedClientInfo(cliente.id)}>
                    <CloseIcon className="closeIcon" />
                  </IconButton>
                </div>
              )}
            </div>
            <div className="idServicio">Provincia: {cliente?.provincia}</div>
            <div className="idServicio">Comunidad: {cliente?.comunidad}</div>
          </CardContent>
          <hr></hr>
          <CardActions className="acciones">
            <Link to={`/clientes/${encodeURIComponent(cliente.id)}/tiendas/${encodeURIComponent(cliente.nombre)}`}>
              <Button size="small" className="verButton">
                Ver
              </Button>
            </Link>
            <div className="rightActions">
              {!cliente.borrado && (
                <Link to={`/clientes/update/${encodeURIComponent(cliente.id)}`}>
                  <IconButton size="small">
                    <EditIcon className="editarIcon" />
                  </IconButton>
                </Link>
              )}
              {cliente.borrado ?
                <Button size="small" className="recuperarButton" onClick={() => handleDelete(cliente.id)}>
                  Recuperar
                </Button>
                : (
                  <IconButton size="small" color="secondary" onClick={() => handleDelete(cliente.id)}>
                    <DeleteIcon className="borrarIcon" />
                  </IconButton>
                )}
            </div>
          </CardActions>
        </Card>
      );
    }
  };

  /**
* Maneja el cambio en la casilla de verificación "Mostrar borrados".
* @param {object} event - Evento de cambio.
*/
  const handleShowDeletedChange = (event) => {
    setShowDeleted(event.target.checked);
  };

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <h2 className="title-registro">Registro Clientes</h2>
        <div className="rightActions">
          <FormControlLabel
            control={
              <Checkbox
                checked={showDeleted}
                onChange={handleShowDeletedChange}
                color="primary"
              />
            }
            label="Mostrar borrados"
          />
          <div className="searchContainer">
            <input
              type="text"
              placeholder="Buscar"
              className="searchInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <SearchIcon className="searchIcon" /> {/* Ícono de lupa usando MUI */}
          </div>
          <Link to="/clientes/newCliente" className="link">
            Nuevo cliente
          </Link>
        </div>
      </div>
      <div className="cardsContainer">
        {filteredData.map(renderClienteCard)}
        {/* {Array(6).fill(null).map((_, index) => renderClienteCard(filteredData[0]))} 
        Esto es para probar como quedarían varias cajas, genera duplicados.*/}
      </div>
    </div>
  );
};

export default DatatableClientes;