import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from '../firebase'; // Ruta correcta al archivo de configuración de Firebase Firestore

/**
 * Obtiene el servicio actual del usuario.
 *
 * @param {Object} user - Objeto de usuario actual.
 * @returns {Promise<Object|null>} Promesa que resuelve en el servicio actual o null si no se encuentra ningún servicio.
 */
const getCurrentService = async (user) => {
  try {
    console.log(user)
    const q = query(
      collection(db, "servicios"),
      where('admins', 'array-contains', user.email));
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // Obtener el primer servicio encontrado
      const serviceDoc = querySnapshot.docs[0];
      const service = { ...serviceDoc.data(), id: serviceDoc.id }; // Agregamos el id del documento
      return service;
    } else {
      console.log("No se encontraron servicios para el usuario actual");
      return null;
    }
  } catch (error) {
    console.error("Error al obtener el servicio actual:", error);
    return null;
  }
};

export default getCurrentService;
