import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { provinciasPorComunidad } from '../../../zoneData';


const ProvinciasSelect = ({ comunidadesSeleccionadas, provinciasSeleccionadas, onSelectProvincias }) => {
  const [provinciasFiltradas, setProvinciasFiltradas] = useState([]);

  useEffect(() => {
    // Lógica para filtrar provincias según las comunidades seleccionadas
    const provinciasDisponibles = obtenerProvinciasDisponibles(comunidadesSeleccionadas);
    setProvinciasFiltradas(provinciasDisponibles);
  }, [comunidadesSeleccionadas]);

  const obtenerProvinciasDisponibles = (comunidades) => {
    // Filtrar las provincias basadas en las comunidades seleccionadas
    const provinciasFiltradas = comunidades.reduce((provincias, comunidad) => {
      return provincias.concat(provinciasPorComunidad[comunidad] || []);
    }, []);
    return Array.from(new Set(provinciasFiltradas));
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="provincias-label">Provincias</InputLabel>
      <Select
        labelId="provincias-label"
        id="provincias"
        multiple
        value={provinciasSeleccionadas} // Asegúrate de que esto se refiera a las provincias seleccionadas
        onChange={(e) => onSelectProvincias(e.target.value)} // Llama al callback con las provincias seleccionadas
        label="Provincias"
      >
        {provinciasFiltradas.map((provincia) => (
          <MenuItem key={provincia} value={provincia}>
            {provincia}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProvinciasSelect;
