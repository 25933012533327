import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { db, storage } from '../../firebase';
import { updateDoc, doc, getDocs, collection, query, limit, where, getDoc } from 'firebase/firestore';
import formFields from './utils/formData'; // Ajusta la ruta según tu estructura de carpetas
import Autocomplete from '@mui/material/Autocomplete';
import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText
} from '@mui/material';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './styles/Profile.scss';
import PersonIcon from '@mui/icons-material/Person';
import { ImageUploader } from './utils/ImageUploader';
import { useAlert } from './utils/alerts';
import ProvinciasSelect from './utils/ProvinciasSelect.js';
import { fetchClientesYTiendas, fetchAdminData, fetchAOtherdminData } from './firestoreService';
import { comunidades, provincias, provinciasPorComunidad } from "../../zoneData.js"
import '@mui/system';

const Profile = () => {
  const { currentUser, currentService } = useContext(AuthContext);
  const [isAllTiendasSelected, setIsAllTiendasSelected] = useState(false);
  const [userData, setUserData] = useState({
    nombre: '',
    apellido: '',
    foto: '',
    puesto: '',
    tipoUsuario: '',
    telefono: '',
    telefonoCorto: '',
    correo: '',
    localizacion: '',
    clientesAsignados: [],
    tiendasAsignadas: [],
    provinciasAsignadas: [],
    comunidadesAsignadas: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [showAlertComponent, AlertComponent] = useAlert();
  const [file, setFile] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [tiendas, setTiendas] = useState([]);
  const [comunidadesSeleccionadas, setComunidadesSeleccionadas] = useState([]);
  const [provinciasSeleccionadas, setProvinciasSeleccionadas] = useState([]);
  const [isAllClientesSelected, setIsAllClientesSelected] = useState(false);
  const [clientesSeleccionados, setClientesSeleccionados] = useState([]);
  const [tiendasSeleccionadas, setTiendasSeleccionadas] = useState([]);

  const fetchData = async () => {
    if (currentUser && currentService) {
      try {
        const adminQuery = query(
          collection(db, 'servicios', currentService.id, 'admins'),
          where('correo', '==', currentUser.email),
          limit(1)
        );
        const adminSnapshot = await getDocs(adminQuery);

        if (!adminSnapshot.empty) {
          const adminData = adminSnapshot.docs[0].data();
          console.log(adminData);

          // Asegúrate de inicializar todos los campos necesarios como arrays,
          // incluso si no existen en los datos devueltos por Firestore.
          setUserData({
            ...userData, // Mantén los datos existentes en userData para evitar sobrescribirlos accidentalmente
            ...adminData, // Aplica los datos obtenidos
            clientesAsignados: adminData.clientes || [],
            tiendasAsignadas: adminData.tiendas || [],
            tipoUsuario: adminData.tipoUsuario || [],
            provinciasAsignadas: adminData.provincias || [],
            comunidadesAsignadas: adminData.comunidades || [],
          });
        }
      } catch (error) {
        console.error('Error al obtener el perfil:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // Cargar datos de clientes y tiendas
    fetchClientesYTiendas(currentService)
      .then(([clientesData, tiendasData]) => {
        setClientes(clientesData);
        setTiendas(tiendasData);
      })
      .catch(console.error);
  }, [currentUser, currentService]);

  const onFileChange = async (file) => {
    setFile(file);

    const storageRef = ref(storage, `profile-photos/${currentUser.email}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Opcional: manejar el progreso de la carga
      },
      (error) => {
        console.error('Error al subir la foto:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log(downloadURL)
          setUserData((prevData) => ({
            ...prevData,
            foto: downloadURL,
          }));
        });
      }
    );
  };

  const handleInputChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === 'foto' && files && files.length > 0) {
      const photoFile = files[0];

      try {
        const storageRef = ref(storage, `profile-photos/${currentUser.email}/${photoFile.name}`);
        const uploadTask = uploadBytesResumable(storageRef, photoFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Handle upload progress if needed
          },
          (error) => {
            console.error('Error uploading photo:', error);
          },
          () => {
            // On successful upload, get the download URL and update the user data
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              setUserData((prevData) => ({
                ...prevData,
                [name]: downloadURL,
              }));
            });
          }
        );
      } catch (error) {
        console.error('Error uploading photo:', error);
      }
    } else {
      // Handle other input changes
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (currentService && currentUser) {
        // Realizar una consulta para obtener el documento con el correo coincidente
        const adminQuery = query(
          collection(db, 'servicios', currentService.id, 'admins'),
          where('correo', '==', currentUser.email),
          limit(1)
        );

        const adminSnapshot = await getDocs(adminQuery);

        if (!adminSnapshot.empty) {
          // Obtener el documento
          const adminDoc = adminSnapshot.docs[0];

          // Obtener los arrays de tipoUsuario
          const adminTipoUsuario = adminDoc.data().tipoUsuario || [];
          const nuevoTipoUsuario = userData.tipoUsuario || [];

          // Verificar que el nuevo tipo de usuario no sea superior al actual
          if ((adminTipoUsuario.includes('administrador') || adminTipoUsuario.includes('administrativo')) &&
            nuevoTipoUsuario.includes('inspector')) {
            // Actualizar el documento
            await updateDoc(adminDoc.ref, {
              clientesAsignados: clientesSeleccionados,
              tiendasAsignadas: tiendasSeleccionadas,
              provinciasAsignadas: provinciasSeleccionadas,
              comunidadesAsignadas: comunidadesSeleccionadas,
            });

            // Mostrar la alerta
            showAlertComponent('success', 'Perfil actualizado', 'Tu perfil ha sido actualizado exitosamente.');

            console.log(userData)
            // Volver a cargar los datos
            fetchData();

            console.log('Perfil actualizado exitosamente');
          } else {
            // Mostrar la alerta de tipo de usuario incorrecto
            showAlertComponent('error', 'Error al actualizar el perfil', 'No puedes cambiar tu tipo de usuario a uno superior.');
          }
        } else {
          console.error('El documento no existe. No se puede actualizar.');
        }
      }
    } catch (error) {
      console.error('Error al actualizar el perfil:', error);
    }
  };

  if (isLoading) {
    return <p>Cargando perfil...</p>;
  }

  const handleSelectProvincias = (provinciasSeleccionadas) => {
    setProvinciasSeleccionadas(provinciasSeleccionadas); // Actualiza el estado local de provincias seleccionadas
    setUserData({ ...userData, provinciasAsignadas: provinciasSeleccionadas }); // Actualiza el objeto newUserData para incluir las provincias seleccionadas
  };

  const handleChangeClientes = (event) => {
    const { value } = event.target;

    if (value.includes('all')) {
      setIsAllClientesSelected(!isAllClientesSelected);
      const nuevosClientesAsignados = isAllClientesSelected ? [] : clientes.map(c => c.id);
      const nuevasTiendasAsignadas = isAllClientesSelected ? [] : tiendas.filter(tienda =>
        nuevosClientesAsignados.includes(tienda.cliente)
      ).map(tienda => tienda.id);

      setUserData(prevAdminData => ({
        ...prevAdminData,
        clientesAsignados: nuevosClientesAsignados,
        tiendasAsignadas: nuevasTiendasAsignadas
      }));
    } else {
      setIsAllClientesSelected(value.length === clientes.length);
      const nuevasTiendasAsignadas = tiendas.filter(tienda =>
        value.includes(tienda.cliente)
      ).map(tienda => tienda.id);

      setUserData(prevAdminData => ({
        ...prevAdminData,
        clientesAsignados: value,
        tiendasAsignadas: nuevasTiendasAsignadas
      }));
    }
  };

  const handleChangeTiendas = (event) => {
    const { value } = event.target;
    if (value.includes('all')) {
      if (isAllTiendasSelected) {
        setUserData({ ...userData, tiendas: [] });
        setIsAllTiendasSelected(false);
      } else {
        setUserData({ ...userData, tiendas: tiendas.map(t => t.id) });
        setIsAllTiendasSelected(true);
      }
    } else {
      setUserData({ ...userData, tiendas: value });
      setIsAllTiendasSelected(value.length === tiendas.length);
    }
  };

  const handleChangeComunidades = (event) => {
    const comunidadesSeleccionadas = event.target.value;
    setComunidadesSeleccionadas(comunidadesSeleccionadas);

    // Filtrar provincias disponibles basadas en comunidades seleccionadas
    const provinciasDisponibles = comunidadesSeleccionadas.reduce((acc, comunidad) => {
      return [...acc, ...(provinciasPorComunidad[comunidad] || [])];
    }, []);

    // Actualizar provincias seleccionadas para incluir solo aquellas disponibles
    const nuevasProvinciasSeleccionadas = userData.provinciasAsignadas.filter(provincia =>
      provinciasDisponibles.includes(provincia)
    );

    setProvinciasSeleccionadas(nuevasProvinciasSeleccionadas); // Actualiza el estado local si es necesario

    // Asegúrate de actualizar el estado global `adminData` con las nuevas comunidades y provincias seleccionadas
    setUserData(prevAdminData => ({
      ...prevAdminData,
      comunidadesAsignadas: comunidadesSeleccionadas,
      provinciasAsignadas: nuevasProvinciasSeleccionadas,
    }));
  };


  return (
    <div className="profile-container">
      <div className="user-section">
        <div className="user-header">
          {userData.foto ? (
            <img className="user-photo" src={userData.foto} alt="User" />
          ) : (
            <PersonIcon className="default-user-icon" />
          )}
          <h2>Mi Perfil</h2>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="form-container">
        {formFields.map((field) => (
          <div key={field.name} className="field-container">
            {field.name === 'foto' ? (
              <>
                <label>{field.label}</label>
                <div className="upload-button">
                  <ImageUploader file={file} setFile={onFileChange} />
                  <input
                    type="file"
                    name={field.name}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            ) : (
              <>
                {field.name === 'tipoUsuario' ? (
                  <div className="field-container">
                    <label>{field.label}</label>
                    <Autocomplete
                      multiple
                      id="tipoUsuario"
                      options={['administrador', 'administrativo', 'inspector']}
                      value={userData.tipoUsuario || []}
                      onChange={(event, newValue) => setUserData((prevData) => ({ ...prevData, tipoUsuario: newValue }))}
                      renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    />
                  </div>
                ) : (
                  <TextField
                    label={field.label}
                    name={field.name}
                    value={userData[field.name] || ''}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                )}
              </>
            )}
          </div>
        ))}
        <FormControl fullWidth id="clientes-form">
          <InputLabel id="clientes-label">Clientes</InputLabel>
          <Select
            labelId="clientes-label"
            id="clientes"
            multiple
            value={userData.clientesAsignados}
            onChange={handleChangeClientes}
            label="Clientes"
          >
            <MenuItem value="all">
              <Checkbox checked={isAllClientesSelected} />
              <ListItemText primary="Seleccionar todos los clientes" />
            </MenuItem>
            {clientes
              .filter((cliente) => {
                // Filtra los clientes basados en las comunidades seleccionadas
                return comunidadesSeleccionadas.length === 0 || comunidadesSeleccionadas.includes(cliente.comunidad);
              })
              .map((cliente) => (
                <MenuItem key={cliente.id} value={cliente.id}>
                  {cliente.nombre}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Servicios</InputLabel>
          <Select
            labelId="tiendas-label"
            id="tiendas"
            multiple
            value={userData.tiendas}
            onChange={handleChangeTiendas}
            label="Servicios"
          >
            <MenuItem value="all" onClick={handleChangeTiendas}>
              <Checkbox checked={isAllTiendasSelected} />
              <ListItemText primary="Seleccionar todos los servicios" />
            </MenuItem>
            {userData.clientesAsignados &&
              tiendas
                .filter((tienda) => userData.clientesAsignados.includes(tienda.cliente))
                .map((tienda) => (
                  <MenuItem key={tienda.id} value={tienda.id}>
                    {tienda.numTienda}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="comunidades-label">Comunidades</InputLabel>
          <Select
            labelId="comunidades-label"
            id="comunidades"
            multiple
            value={userData.comunidades}
            onChange={handleChangeComunidades}
            label="Comunidades"
          >
            {comunidades.map((comunidad) => (
              <MenuItem key={comunidad} value={comunidad}>
                {comunidad}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ProvinciasSelect
          comunidadesSeleccionadas={comunidadesSeleccionadas}
          provinciasSeleccionadas={provinciasSeleccionadas}
          onSelectProvincias={handleSelectProvincias}
        />
        <Button variant="contained" type="submit" className="small">
          Guardar Cambios
        </Button>
      </form>
      <AlertComponent />
    </div>
  );
};

export default Profile;