import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  arrayUnion,
  limit,
  getDoc,
  getDocsFromCache
} from 'firebase/firestore';
import { db, auth } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';


export const fetchAdmins = async (currentService) => {
  const adminQuery = query(collection(db, 'servicios', currentService.id, 'admins'));
  const adminSnapshot = await getDocs(adminQuery);
  return adminSnapshot.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  });
};


export const fetchClientesYTiendas = async (currentService) => {
  const clientesRef = collection(db, 'servicios', currentService.id, 'clientes');
  const tiendasRef = collection(db, 'servicios', currentService.id, 'tiendas');

  const [clientesSnapshot, tiendasSnapshot] = await Promise.all([
    getDocs(clientesRef),
    getDocs(tiendasRef)
  ]);

  const clientesData = clientesSnapshot.docs
    .filter(doc => !doc.data().borrado) // Filtrar los documentos con borrado igual a false
    .map(doc => ({ id: doc.id, ...doc.data() }));

  const tiendasData = tiendasSnapshot.docs
    .filter(doc => !doc.data().borrado) // Filtrar los documentos con borrado igual a false
    .map(doc => ({ id: doc.id, ...doc.data() }));

  return [clientesData, tiendasData];
};


export const addNewUser = async (newUserData, currentService) => {
  const { user } = await createUserWithEmailAndPassword(auth, newUserData.correo, newUserData.password);
  const usuarioDoc = doc(db, 'servicios', currentService.id, 'admins');
  await addDoc(usuarioDoc, {
    correo: newUserData.correo,
    nombre: newUserData.nombre,
    tipoUsuario: newUserData.tipoUsuario,
    userId: user.uid
  });
  return user.uid; // Devuelve el ID del nuevo usuario
};


export const updateClientesYTiendas = async (userId, tipoUsuario, newUserData, currentService) => {
  const esAdministrativo = tipoUsuario.includes('administrativo');
  const esInspector = tipoUsuario.includes('inspector');

  const updateRef = async (refPath, field, value) => {
    const ref = doc(db, refPath);
    await updateDoc(ref, { [field]: arrayUnion(value) });
  };

  for (const clienteId of newUserData.clientes) {
    if (esAdministrativo) {
      await updateRef(`servicios/${currentService.id}/clientes/${clienteId}`, 'administrativos', userId);
    }
    if (esInspector) {
      await updateRef(`servicios/${currentService.id}/clientes/${clienteId}`, 'inspectores', userId);
    }
  }

  for (const tiendaId of newUserData.tiendas) {
    if (esAdministrativo) {
      await updateRef(`servicios/${currentService.id}/tiendas/${tiendaId}`, 'administrativos', userId);
    }
    if (esInspector) {
      await updateRef(`servicios/${currentService.id}/tiendas/${tiendaId}`, 'inspectores', userId);
    }
  }
};

export const fetchUserData = async (currentUser, currentService) => {
  if (currentUser && currentService) {
    try {
      const adminQuery = query(
        collection(db, 'servicios', currentService.id, 'admins'),
        where('correo', '==', currentUser.email),
        limit(1)
      );
      const adminSnapshot = await getDocs(adminQuery);

      if (!adminSnapshot.empty) {
        const adminData = adminSnapshot.docs[0].data();
        const adminId = adminSnapshot.docs[0].id; // Obtener el ID del documento
        return { ...adminData, id: adminId };
      } else {
        throw new Error("No se encontraron datos del administrador.");
      }
    } catch (error) {
      throw error;
    }
  }
};

// Función para obtener clientes basada en el ID del administrador
export const fetchClientesPorAdministrativo = async (adminId, servicio) => {
  const clientesRef = collection(db, 'servicios', servicio.id, 'clientes');
  const q = query(clientesRef, where('administrativos', 'array-contains', adminId));
  const querySnapshot = await getDocs(q);
  const clientes = [];
  querySnapshot.forEach((doc) => {
    clientes.push({ id: doc.id, ...doc.data() });
  });
  return clientes;
};

// Función para obtener clientes basada en el ID del administrador
export const fetchClientesPorInspector = async (adminId, servicio) => {
  const clientesRef = collection(db, 'servicios', servicio.id, 'clientes');
  const q = query(clientesRef, where('inspectores', 'array-contains', adminId));
  const querySnapshot = await getDocs(q);
  const clientes = [];
  querySnapshot.forEach((doc) => {
    clientes.push({ id: doc.id, ...doc.data() });
  });
  return clientes;
};

export const fetchTiendasPorCampoCliente = async (clientesIds, servicio) => {
  const tiendasRef = collection(db, 'servicios', servicio.id, 'tiendas');
  let tiendas = [];

  // Función externa para comparar tiendas por su ID
  const isTiendaDuplicada = (tiendaId) => tiendas.some(tienda => tienda.id === tiendaId);
  
  // Realizar una consulta por cada clienteId y combinar los resultados
  for (const clienteId of clientesIds) {
    const q = query(tiendasRef, where('cliente', '==', clienteId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const tiendaData = { id: doc.id, ...doc.data() };
      // Asegurarse de no incluir tiendas duplicadas
      if (!isTiendaDuplicada(doc.id)) {
        tiendas.push(tiendaData);
      }
    });
  }

  // Filtrar tiendas que tienen 'borrado' igual a true
  tiendas = tiendas.filter(tienda => tienda.borrado !== true);

  return tiendas;
};


export const fetchAdminData = async (currentUser, currentService) => {
  if (currentUser && currentService) {
    try {
      const adminQuery = query(
        collection(db, 'servicios', currentService.id, 'admins'),
        where('correo', '==', currentUser.email),
        limit(1)
      );
      const adminSnapshot = await getDocs(adminQuery);

      if (!adminSnapshot.empty) {
        const adminData = adminSnapshot.docs[0].data();
        return { ...adminData, id: adminSnapshot.docs[0].id };
      } else {
        throw new Error("No se encontraron datos del administrador.");
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchAOtherdminData = async (adminId, currentService) => {
  if (adminId && currentService) {
    try {
      const adminRef = doc(db, 'servicios', currentService.id, 'admins', adminId);
      const adminSnapshot = await getDoc(adminRef);

      if (adminSnapshot.exists()) {
        const adminData = adminSnapshot.data();
        return { ...adminData, id: adminSnapshot.id };
      } else {
        throw new Error("No se encontraron datos del administrador.");
      }
    } catch (error) {
      throw error;
    }
  }
};