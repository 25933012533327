import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { query, collection, where, getDocs, doc, limit, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';
import { fetchClientesYTiendas, fetchAdminData, fetchAOtherdminData } from './firestoreService';
import { useAlert } from './utils/alerts';
import { AuthContext } from '../../context/AuthContext';
import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    ListItemText
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import "./styles/AddUser.scss";
import { comunidades, provincias, provinciasPorComunidad } from "../../zoneData.js"
import ProvinciasSelect from './utils/ProvinciasSelect.js';
import { useNavigate } from 'react-router-dom';

const EditAdmin = () => {
    const { adminId } = useParams();
    const [adminData, setAdminData] = useState({
        nombre: '',
        apellido: '',
        correo: '',
        tipoUsuario: [],
        clientesAsignados: [],
        tiendasAsignadas: [],
        provinciasAsignadas: [],
        comunidadesAsignadas: []
    });
    const [clientes, setClientes] = useState([]);
    const [tiendas, setTiendas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tiendasFiltradas, setTiendasFiltradas] = useState([]);
    const [isAllClientesSelected, setIsAllClientesSelected] = useState(false);
    const [provinciasSeleccionadas, setProvinciasSeleccionadas] = useState([]);
    const [comunidadesSeleccionadas, setComunidadesSeleccionadas] = useState([]);
    const [isAllTiendasSelected, setIsAllTiendasSelected] = useState(false);
    const [newTipoUsuario, setNewTipoUsuario] = useState([]);
    const [clientesFiltrados, setClientesFiltrados] = useState([]);

    const { currentUser, currentService } = useContext(AuthContext);
    const navigate = useNavigate(); // Obtener la función de navegación

    useEffect(() => {
        if (currentService && adminId) {
            fetchAOtherdminData(adminId, currentService)
                .then((data) => {
                    setAdminData({
                        ...data,
                        clientesAsignados: data.clientes || [],
                        tiendasAsignadas: data.tiendas || [],
                        tipoUsuario: data.tipoUsuario || [],
                        provinciasAsignadas: data.provincias || [],
                        comunidadesAsignadas: data.comunidades || []
                    });
                })
                .catch(console.error);

            // Cargar datos de clientes y tiendas
            fetchClientesYTiendas(currentService)
                .then(([clientesData, tiendasData]) => {
                    setClientes(clientesData);
                    setTiendas(tiendasData);
                })
                .catch(console.error);
        }
    }, [currentUser, currentService, adminId]);

    useEffect(() => {
        // Filtrar tiendas basadas en los clientes seleccionados
        const filteredTiendas = tiendas.filter(tienda =>
            adminData.clientesAsignados.includes(tienda.cliente)
        );

        // Actualizar el estado local de tiendasAsignadas
        setAdminData(prevData => ({
            ...prevData,
            tiendasAsignadas: filteredTiendas.map(tienda => tienda.id)
        }));
    }, [adminData.clientesAsignados, tiendas]);

    useEffect(() => {
        setProvinciasSeleccionadas(adminData.provinciasAsignadas || []);
        setComunidadesSeleccionadas(adminData.comunidadesAsignadas || []);
    }, [adminData.provinciasAsignadas]);


    const handleUpdate = async () => {
        setLoading(true);

        const adminRef = doc(db, 'servicios', currentService.id, 'admins', adminId);
        const lowercaseEmail = adminData.correo.toLowerCase();

        try {
            // Actualizar tipo de usuario si hay cambios
            if (newTipoUsuario.length > 0) {
                await updateDoc(adminRef, {
                    tipoUsuario: newTipoUsuario,
                });
            }

            await updateDoc(adminRef, {
                nombre: adminData.nombre,
                apellido: adminData.apellido || null,
                correo: lowercaseEmail,
                clientes: adminData.clientesAsignados,
                tiendas: adminData.tiendasAsignadas,
                provincias: adminData.provinciasAsignadas,
                comunidades: adminData.comunidadesAsignadas,
            });

            await updateClientesYTiendas(
                adminId,
                newTipoUsuario.length > 0 ? newTipoUsuario : adminData.tipoUsuario,
                adminData.clientesAsignados,
                adminData.tiendasAsignadas
            );

            window.location.reload();
            alert('Perfil actualizado correctamente');
            navigate(-1);
        } catch (err) {
            alert('Error al actualizar el usuario');
        } finally {
            setLoading(false);
        }
    };

    const handleChangeTipoUsuario = (event) => {
        const { value } = event.target;
        setNewTipoUsuario(value);
    };

    const handleChangeClientes = (event) => {
        const { value } = event.target;

        if (value.includes('all')) {
            setIsAllClientesSelected(!isAllClientesSelected);
            const nuevosClientesAsignados = isAllClientesSelected ? [] : clientes.map(c => c.id);
            const nuevasTiendasAsignadas = isAllClientesSelected ? [] : tiendas.filter(tienda =>
                nuevosClientesAsignados.includes(tienda.cliente)
            ).map(tienda => tienda.id);

            setAdminData(prevAdminData => ({
                ...prevAdminData,
                clientesAsignados: nuevosClientesAsignados,
                tiendasAsignadas: nuevasTiendasAsignadas
            }));
        } else {
            setIsAllClientesSelected(value.length === clientes.length);
            const nuevasTiendasAsignadas = tiendas.filter(tienda =>
                value.includes(tienda.cliente)
            ).map(tienda => tienda.id);

            setAdminData(prevAdminData => ({
                ...prevAdminData,
                clientesAsignados: value,
                tiendasAsignadas: nuevasTiendasAsignadas
            }));
        }
    };


    const handleChangeComunidades = (event) => {
        const comunidadesSeleccionadas = event.target.value;
        setComunidadesSeleccionadas(comunidadesSeleccionadas);

        // Filtrar provincias disponibles basadas en comunidades seleccionadas
        const provinciasDisponibles = comunidadesSeleccionadas.reduce((acc, comunidad) => {
            return [...acc, ...(provinciasPorComunidad[comunidad] || [])];
        }, []);

        // Actualizar provincias seleccionadas para incluir solo aquellas disponibles
        const nuevasProvinciasSeleccionadas = adminData.provinciasAsignadas.filter(provincia =>
            provinciasDisponibles.includes(provincia)
        );

        setProvinciasSeleccionadas(nuevasProvinciasSeleccionadas); // Actualiza el estado local si es necesario

        // Asegúrate de actualizar el estado global `adminData` con las nuevas comunidades y provincias seleccionadas
        setAdminData(prevAdminData => ({
            ...prevAdminData,
            comunidadesAsignadas: comunidadesSeleccionadas,
            provinciasAsignadas: nuevasProvinciasSeleccionadas,
        }));
    };


    const updateClientesYTiendas = async (userId, tipoUsuario, clientesAsignados, tiendasAsignadas) => {
        const esAdministrativo = tipoUsuario.includes('administrativo');
        const esInspector = tipoUsuario.includes('inspector');

        // Actualizar clientes
        for (const clienteId of clientesAsignados) {
            const clienteRef = doc(db, 'servicios', currentService.id, 'clientes', clienteId);
            if (esAdministrativo) {
                await updateDoc(clienteRef, { administrativos: arrayUnion(userId) });
            }
            if (esInspector) {
                await updateDoc(clienteRef, { inspectores: arrayUnion(userId) });
            }
        }

        // Actualizar tiendas
        for (const tiendaId of tiendasAsignadas) {
            const tiendaRef = doc(db, 'servicios', currentService.id, 'tiendas', tiendaId);
            if (esAdministrativo) {
                await updateDoc(tiendaRef, { administrativos: arrayUnion(userId) });
            }
            if (esInspector) {
                await updateDoc(tiendaRef, { inspectores: arrayUnion(userId) });
            }
        }
    };

    const handleSelectAllTiendas = () => {
        const allTiendas = tiendas.map((tienda) => tienda.id);
        setAdminData((prevAdminData) => ({
            ...prevAdminData,
            tiendasAsignadas: allTiendas,
        }));
    };

    console.log(provinciasSeleccionadas, comunidadesSeleccionadas)

    const handleSelectProvincias = (provinciasSeleccionadas) => {
        setProvinciasSeleccionadas(provinciasSeleccionadas); // Actualiza el estado local de provincias seleccionadas
        setAdminData({ ...adminData, provinciasAsignadas: provinciasSeleccionadas }); // Actualiza el objeto newUserData para incluir las provincias seleccionadas
    };

    // Formulario para editar los datos del administrador
    return (
        <div className="add-user-container">
            <h1>Editar Usuario</h1>
            <div className="form-container">
                <div className="form-control">
                    <TextField
                        label="Nombre"
                        value={adminData.nombre}
                        onChange={(e) => setAdminData({ ...adminData, nombre: e.target.value })}
                    />
                </div>
                <div className="form-control">
                    <TextField
                        label="Apellidos"
                        value={adminData.apellido}
                        onChange={(e) => setAdminData({ ...adminData, apellido: e.target.value })}
                    />
                </div>
                {/* Otros campos como apellido y correo */}
                {/* Campos para seleccionar clientes y tiendas */}
                <FormControl fullWidth>
                    <InputLabel id="tipoUsuario-label">Tipo de Usuario</InputLabel>
                    <Select
                        labelId="tipoUsuario-label"
                        id="tipoUsuario"
                        multiple
                        value={adminData.tipoUsuario}
                        onChange={handleChangeTipoUsuario}
                        label="Tipo de Usuario"
                    >
                        <MenuItem value="administrador">Administrador</MenuItem>
                        <MenuItem value="administrativo">Administrativo</MenuItem>
                        <MenuItem value="inspector">Inspector</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="clientes-label">Clientes</InputLabel>
                    <Select
                        labelId="clientes-label"
                        id="clientes"
                        multiple
                        value={adminData.clientesAsignados}
                        onChange={handleChangeClientes}
                        label="Clientes"
                    >
                        <MenuItem value="all">
                            <Checkbox checked={isAllClientesSelected} />
                            <ListItemText primary="Seleccionar todos los clientes" />
                        </MenuItem>
                        {clientes
                            .filter((cliente) => {
                                // Filtra los clientes basados en las comunidades seleccionadas
                                return comunidadesSeleccionadas.length === 0 || comunidadesSeleccionadas.includes(cliente.comunidad);
                            })
                            .map((cliente) => (
                                <MenuItem key={cliente.id} value={cliente.id}>
                                    {cliente.nombre}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel>Servicios</InputLabel>
                    <Select
                        multiple
                        value={adminData.tiendasAsignadas}
                        onChange={(e) => setAdminData({ ...adminData, tiendasAsignadas: e.target.value })}
                    >
                        <MenuItem value="" onClick={handleSelectAllTiendas}>
                            Seleccionar todos las servicios
                        </MenuItem>
                        {adminData.clientesAsignados &&
                            tiendas
                                .filter((tienda) => adminData.clientesAsignados.includes(tienda.cliente))
                                .map((tienda) => (
                                    <MenuItem key={tienda.id} value={tienda.id}>
                                        {tienda.numTienda}
                                    </MenuItem>
                                ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id="comunidades-label">Comunidades</InputLabel>
                    <Select
                        labelId="comunidades-label"
                        id="comunidades"
                        multiple
                        value={adminData.comunidadesAsignadas}
                        onChange={handleChangeComunidades}
                        label="Comunidades"
                    >
                        {comunidades.map((comunidad) => (
                            <MenuItem key={comunidad} value={comunidad}>
                                {comunidad}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <ProvinciasSelect
                    comunidadesSeleccionadas={comunidadesSeleccionadas}
                    provinciasSeleccionadas={provinciasSeleccionadas}
                    onSelectProvincias={handleSelectProvincias}
                />
            </div>
            <Button onClick={handleUpdate} variant="contained" color="primary" style={{ margin: '20px' }}>
                {loading ? (
                    <>
                        Actualizando usuario...
                        <CircularProgress size={20} style={{ marginLeft: '10px', color: '#fff' }} />
                    </>
                ) : (
                    'Actualizar'
                )}
            </Button>
        </div>
    );
};

export default EditAdmin;