const customLocaleText = {
  columnMenuUnsort: "Sin clasificar",
  columnMenuSortAsc: "Ordenar en orden ascendente",
  columnMenuSortDesc: "Ordenar en orden descendente",
  columnMenuFilter: "Filtrar",
  columnMenuHideColumn: "Ocultar columna",
  columnMenuShowColumns: "Mostrar columnas",
  toolbarColumns: "Columnas",
  toolbarFilters: "Filtrar",
  toolbarColumnsLabel: 'Seleccionar columnas',

  // Export selector toolbar button text
  toolbarExport: "Exportar",
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Descargar como CSV',
  toolbarExportPrint: 'Imprimir',
  toolbarExportExcel: 'Descargar como Excel',

  // Density selector toolbar button text
  toolbarDensity: "Densidad",
  toolbarDensityLabel: 'Densidad',
  toolbarDensityCompact: 'Compacto',
  toolbarDensityStandard: 'Estándar',
  toolbarDensityComfortable: 'Comfortable',

  // Filter panel text
  filterPanelOperator: 'Operador',
  filterPanelRemoveAll: 'Borrar todo',
  filterPanelAddFilter: 'Añadir filtro',
  filterPanelDeleteIconLabel: 'Borrar',
  filterPanelLinkOperator: 'Operador lógico',
  filterPanelOperators: 'Operador', // TODO v6: rename to filterPanelOperator
  filterPanelOperatorAnd: 'Y',
  filterPanelOperatorOr: 'O',
  filterPanelColumns: 'Columnas',
  filterPanelInputLabel: 'Valor',
  filterPanelInputPlaceholder: 'Filtrar Valor',
  // Filter operators text
  filterOperatorContains: 'contiene',
  filterOperatorEquals: 'igual',
  filterOperatorStartsWith: 'empieza por',
  filterOperatorEndsWith: 'acaba por',
  filterOperatorIs: 'es',
  filterOperatorNot: 'no es',
  filterOperatorAfter: 'está después',
  filterOperatorOnOrAfter: 'no está después',
  filterOperatorBefore: 'está antes',
  filterOperatorOnOrBefore: 'está en o antes',
  filterOperatorIsEmpty: 'está vacío',
  filterOperatorIsNotEmpty: 'no está vacío',
  filterOperatorIsAnyOf: 'es uno de',

  // Filter values text
  filterValueAny: 'alguno',
  filterValueTrue: 'verdadero',
  filterValueFalse: 'falso',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Encontrar columna',
  columnsPanelTextFieldPlaceholder: 'Título de la columna',
  columnsPanelDragIconLabel: 'Reordenar columna',
  columnsPanelShowAllButton: 'Mostrar todo',
  columnsPanelHideAllButton: 'Ocultar todo',

  // Texto del menú de la columna
  columnMenuLabel: 'Menú',
  columnMenuShowColumns: 'Mostrar columnas',
  columnMenuManageColumns: 'Gestionar columnas',
  columnMenuFilter: 'Filtrar',
  columnMenuHideColumn: 'Ocultar columna',
  columnMenuUnsort: 'Sin clasificar',
  columnMenuSortAsc: 'Ordenar ascendente',
  columnMenuSortDesc: 'Ordenar descendente',

  // Texto del encabezado de la columna
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} filtros activos` : `${count} filtro activo`,
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenar',

  // Texto del pie de página cuando se seleccionan filas
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} filas seleccionadas`
      : `${count.toLocaleString()} fila seleccionada`,

  // Texto del pie de página para la cantidad total de filas
  footerTotalRows: 'Total de filas:',

  // Texto del pie de página para la cantidad total de filas visibles
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} de ${totalCount.toLocaleString()}`,

  // Texto de selección de casilla de verificación
  checkboxSelectionHeaderName: 'Selección de casilla de verificación',
  checkboxSelectionSelectAllRows: 'Seleccionar todas las filas',
  checkboxSelectionUnselectAllRows: 'Deseleccionar todas las filas',
  checkboxSelectionSelectRow: 'Seleccionar fila',
  checkboxSelectionUnselectRow: 'Deseleccionar fila',

  // Texto de celda booleana
  booleanCellTrueLabel: 'sí',
  booleanCellFalseLabel: 'no',

  // Texto adicional de la celda de acciones
  actionsCellMore: 'más',

  // Texto de anclaje de columna
  pinToLeft: 'Anclar a la izquierda',
  pinToRight: 'Anclar a la derecha',
  unpin: 'Desanclar',

  // Datos de árbol
  treeDataGroupingHeaderName: 'Grupo',
  treeDataExpand: 'ver hijos',
  treeDataCollapse: 'ocultar hijos',

  // Columnas de agrupación
  groupingColumnHeaderName: 'Grupo',
  groupColumn: (name) => `Agrupar por ${name}`,
  unGroupColumn: (name) => `Dejar de agrupar por ${name}`,

  // Maestro/detalle
  detailPanelToggle: 'Alternar panel de detalles',
  expandDetailPanel: 'Expandir',
  collapseDetailPanel: 'Contraer',

  // Claves de traducción utilizadas en los componentes centrales
  MuiTablePagination: {},

  // Texto de reordenamiento de filas
  rowReorderingHeaderName: 'Reordenamiento de filas',

  // Agregación
  aggregationMenuItemHeader: 'Agregación',
  aggregationFunctionLabelSum: 'suma',
  aggregationFunctionLabelAvg: 'promedio',
  aggregationFunctionLabelMin: 'mínimo',
  aggregationFunctionLabelMax: 'máximo',
  aggregationFunctionLabelSize: 'tamaño',
};

export default customLocaleText;