import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { db } from '../../firebase';
import { updateDoc, doc } from 'firebase/firestore';
import { Button, Paper, Typography } from '@mui/material';
import { fetchAdmins, fetchUserData } from './firestoreService';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import "./styles/Config.scss"

// ... (importaciones y estilos)

// ... (importaciones y estilos)

const Config = () => {
    const { currentUser, currentService } = useContext(AuthContext);
    const [userData, setUserData] = useState({});
    const [isLoadingUserData, setIsLoadingUserData] = useState(true);
    const [showDeletedUsers, setShowDeletedUsers] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [users, setUsers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser && currentService) {
            setIsLoadingUserData(true);

            fetchUserData(currentUser, currentService)
                .then((data) => {
                    setUserData(data);
                })
                .catch(console.error)
                .finally(() => {
                    setIsLoadingUserData(false);
                });
        }
    }, [currentUser, currentService]);

    useEffect(() => {
        if (userData.tipoUsuario && !isLoadingUserData) {
            fetchAdmins(currentService)
                .then((adminsData) => {
                    const filteredUsers = adminsData.filter((admin) => {
                        const userRoles = userData.tipoUsuario;
                        const adminRoles = admin.tipoUsuario;

                        if (userRoles.includes('administrador')) {
                            // El administrador ve a todos
                            return true;
                        } else if (userRoles.includes('administrativo')) {
                            // Los administrativos ven a inspectores que comparten clientes
                            return (
                                (adminRoles.includes('inspector') || adminRoles.includes('administrativo')) &&
                                admin.clientes.some(cliente => userData.clientes.includes(cliente))
                            );
                        } else if (userRoles.includes('inspector')) {
                            // Los inspectores ven a otros inspectores que comparten clientes
                            return (
                                adminRoles.includes('inspector') &&
                                admin.clientes.some(cliente => userData.clientes.includes(cliente))
                            );
                        }
                        return false;
                    });

                    // Filtra los usuarios según si se deben mostrar eliminados o no
                    const usersToShow = filteredUsers.filter(user => showDeletedUsers ? user.eliminado : !user.eliminado);

                    setUsers(usersToShow);
                })
                .catch(console.error);
        }
    }, [currentService, userData, isLoadingUserData, showDeletedUsers]);

    const navigateToEditAdmin = (adminId) => {
        navigate(`/edit-admin/${adminId}`);
    };

    const navigateToAddUser = () => {
        navigate('/add-user');
    };

    const handleDeleteUser = async (userId, isDeleted) => {
        // Asegúrate de que se está pasando el ID correcto al llamar esta función
        if (!userId) {
            console.error('ID de usuario no proporcionado');
            return;
        }

        // Determinar el mensaje de la alerta
        const confirmationMessage = isDeleted
            ? '¿Estás seguro de que quieres eliminar al usuario?'
            : '¿Estás seguro de que quieres recuperar al usuario?';

        // Mostrar alerta de confirmación antes de eliminar/recuperar
        const confirmAction = window.confirm(confirmationMessage);

        if (!confirmAction) {
            return; // El usuario canceló la acción
        }

        try {
            // Obtén el tipo de usuario del usuario que se va a eliminar/recuperar
            const userToDelete = users.find(user => user.id === userId);
            const userType = userToDelete?.tipoUsuario || [];

            // Comprueba si el usuario es de tipo 'administrador'
            if (userType.includes('administrador')) {
                console.log('No puedes eliminar/recuperar a un administrador.');
                alert(`No puedes ${isDeleted ? 'eliminar' : 'recuperar'} a un administrador.`);
                return;
            }

            // Si no es un administrador, procede con la eliminación/recuperación
            const userRef = doc(db, 'servicios', currentService.id, 'admins', userId);
            await updateDoc(userRef, { eliminado: isDeleted });

            // Actualiza el estado local con los cambios
            setUsers(prevUsers =>
                prevUsers.map(user => (user.id === userId ? { ...user, eliminado: isDeleted } : user))
            );

            alert(`Usuario ${isDeleted ? 'eliminado' : 'recuperado'} correctamente`);
        } catch (error) {
            console.error(`Error al ${isDeleted ? 'eliminar' : 'recuperar'} el usuario:`, error);
        }
    };

    return (
        <div className="config-container">
            {isLoadingUserData ? (
                <p>Cargando...</p>
            ) : (
                <div>
                    <h1 className="usuarios-title">Usuarios</h1>
                    <div className='buttonAdd'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showDeletedUsers}
                                    onChange={() => setShowDeletedUsers(!showDeletedUsers)}
                                />
                            }
                            label="Mostrar usuarios eliminados"
                        />
                        <Button onClick={navigateToAddUser} variant="contained" color="primary" style={{ margin: '20px' }}>
                            Añadir Nuevo Usuario
                        </Button>
                    </div>
                    {users.map((user) => (
                        <div className='paperContainer' key={user.id}>
                            <Paper elevation={3} className="user-paper">
                                <Typography variant="h6">{user.nombre} {user.apellido}</Typography>
                                <Typography variant="body1">Correo: {user.correo}</Typography>
                                <Typography variant="body2">Tipo de Usuario: {user.tipoUsuario.join(', ')}</Typography>
                                {userData && (userData?.tipoUsuario?.includes('administrador') ||
                                    (userData?.tipoUsuario?.includes('administrativo') && !user?.tipoUsuario?.includes('administrador'))) && (
                                        <Button onClick={() => navigateToEditAdmin(user.id)}>Editar</Button>
                                    )}
                                <Button onClick={() => handleDeleteUser(user.id, !user.eliminado)}>
                                    {user.eliminado ? 'Recuperar' : 'Eliminar'}
                                </Button>
                            </Paper>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Config;

