import React from 'react';

const ArrayCellRenderer = ({ values }) => {
  return (
    <div className="custom-scrollbar" style={{
      display: 'flex',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    }}>
      {values?.map((value, index) => (
        <div key={index} style={{
          borderRadius: '10px',
          background: 'rgba(73, 80, 87, 0.27)',
          padding: '5px 10px',
          color: 'black',
          marginRight: '5px',
          marginBottom: '5px',
        }}>
          {value}
        </div>
      ))}
    </div>
  );
};

export default ArrayCellRenderer;
