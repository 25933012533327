import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { AuthContext } from "../../context/AuthContext";
import { collection, addDoc, getDocs, where, query, doc, getDoc, updateDoc } from "firebase/firestore";
import { TextField, Select, MenuItem, Button, Typography, InputLabel, FormControl, Checkbox, FormControlLabel } from '@mui/material'; // Importamos los componentes de Material-UI
import './styles/formCreator.scss';
import FormPreview from './FormPreview';
import { v4 as uuidv4 } from 'uuid';
import IconPicker from './formUtils/IconPicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const FormUpdate = () => {
    const { currentUser, adminData } = useContext(AuthContext);
    const [form, setForm] = useState({
        icon: '',
        export: '',
        name: '',
        inputs: {}
    });
    const [servicio, setServicio] = useState(null);
    const [errors, setErrors] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [selectedInput, setSelectedInput] = useState(null);
    const [selectedInputType, setSelectedInputType] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
    const [selectedClient, setSelectedClient] = useState('all');
    const [clients, setClients] = useState([]);


    const { id: existingFormId } = useParams();  // Obtiene el id de los parámetros de la URL

    const fetchServicio = async () => {
        try {
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const primerServicio = querySnapshot.docs[0];
                setServicio({ id: primerServicio.id, ...primerServicio.data() });
            }
        } catch (error) {
            console.error('Error al obtener el servicio:', error);
        }
    };

    const fetchClients = async () => {
        try {
          if (adminData && adminData.clientes && servicio) {
            const clientsRef = collection(db, 'servicios', servicio.id, 'clientes');
            const clientDocs = await getDocs(clientsRef);
      
            const clientsList = clientDocs.docs
              .filter(doc => adminData.clientes.includes(doc.id)) // Filtra solo los clientes asignados al admin
              .map(doc => ({ id: doc.id, ...doc.data() }));
      
            setClients(clientsList);
          }
        } catch (error) {
          console.error('Error al obtener los clientes:', error);
        }
      };
      

    useEffect(() => {
        if (servicio) {
            fetchClients();
        }
    }, [servicio]);

    useEffect(() => {
        if (currentUser && existingFormId && servicio) {
            loadFormData(existingFormId);
        }
    }, [currentUser, existingFormId, servicio]);


    useEffect(() => {
        if (currentUser) {
            fetchServicio();
        }
    }, [currentUser]);

    const isValid = () => {
        let errors = {};

        if (!form.name.trim()) errors.name = 'El nombre del formulario es obligatorio.';
        // if (!form.icon.trim()) errors.icon = 'El icono es obligatorio.'; //TODO POR AHORA NO ES NECESARIO INGRESAR ICONO
        if (!form.export.trim()) errors.export = 'El tipo de exportación es obligatorio.';

        // Validación de inputs
        for (let input in form.inputs) {
            if (!form.inputs[input].title.trim()) errors[`${input}_title`] = 'El nombre del campo es obligatorio.';
            if (!form.inputs[input].name.trim()) errors[`${input}_name`] = 'El nombre es obligatorio.';
            if ((form.inputs[input].type === 'DROPBOX' || form.inputs[input].type === 'MULTI_DROPBOX') &&
                (!form.inputs[input].dropbox || form.inputs[input].dropbox.length === 0)) {
                errors[`${input}_options`] = 'Debes agregar al menos una opción.';
            }
        }

        setErrors(errors);
        return Object.keys(errors).length === 0; // Devuelve true si no hay errores
    };

    const loadFormData = async (formId) => {
        try {
            if (!servicio) {
                console.error('No se ha cargado el servicio aún.');
                return;
            }

            const formRef = doc(db, 'servicios', servicio.id, 'forms', formId);
            const docSnap = await getDoc(formRef);
            if (docSnap.exists()) {
                const formData = docSnap.data();
                setForm({
                    icon: formData?.icon,
                    export: formData.export,
                    name: formData.name,
                    inputs: formData.inputs || {},
                    cliente: formData.cliente || []
                });
                // Asegúrate de que la selección previa se carga correctamente:
                if (formData.cliente && formData.cliente.length === clients.length) {
                    setSelectedClient('all');
                } else if (formData.cliente && formData.cliente.length === 1) {
                    setSelectedClient(formData.cliente[0]);
                }
            }
        } catch (error) {
            console.error('Error al cargar los datos del formulario:', error);
        }
    };


    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
      
        if (name === 'cliente') {
          setSelectedClient(value);
      
          if (value === 'all') {
            // Obtén los IDs de todos los clientes y agrégales al array del formulario
            const allClientIds = clients.map(client => client.id);
            setForm(prev => ({ ...prev, [name]: allClientIds }));
          } else {
            // Si se selecciona un cliente específico, agrégalo al array del formulario
            setForm(prev => ({ ...prev, [name]: [value] }));
          }
        } else {
          setForm({
            ...form,
            [name]: value,
          });
        }
      };

    const handleInputClick = (id) => {
        setSelectedInput(id);
    };

    const handleTitleChange = (id, newTitle) => {
        const updatedInputs = { ...form.inputs };
        updatedInputs[id].title = newTitle;

        // Genera automáticamente el valor 'name' basado en el 'title'
        updatedInputs[id].name = newTitle; // Convertir el título a un formato adecuado para un nombre de campo
        setForm({
            ...form,
            inputs: updatedInputs
        });
    };

    const handleInputUpdate = (title, updatedInput) => {
        // Actualiza solo el input específico manteniendo el resto inalterado
        setForm((prevForm) => ({
            ...prevForm,
            inputs: {
                ...prevForm.inputs,
                [title]: {
                    ...prevForm.inputs[title],
                    ...updatedInput,
                },
            },
        }));
    };

    const handleOpenSuccessDialog = () => {
        setOpenSuccessDialog(true);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!isValid()) {
            handleOpenModal();
            return;
        }

        if (!servicio) {
            console.error('No se encontró un servicio para el usuario actual.');
            return;
        }

        const inputsWithoutId = Object.keys(form.inputs).reduce((acc, key) => {
            const { id, ...rest } = form.inputs[key];
            acc[rest.title] = rest;
            return acc;
        }, {});

        try {
            const formsRef = collection(db, 'servicios', servicio.id, 'forms');
            if (existingFormId) {
                const formDocRef = doc(db, 'servicios', servicio.id, 'forms', existingFormId);
                await updateDoc(formDocRef, { ...form, inputs: inputsWithoutId, name: form.name });
                console.log('Formulario actualizado correctamente:', form);
                handleOpenSuccessDialog();
            } else {
                await addDoc(formsRef, { ...form, inputs: inputsWithoutId, name: form.name });
                console.log('Formulario creado correctamente:', form);
            }
        } catch (error) {
            console.error('Error al enviar el formulario:', error);
        }
    };


    const handleAddOption = (title) => {
        const updatedInputs = { ...form.inputs };
        updatedInputs[title].dropbox = updatedInputs[title].dropbox ? [...updatedInputs[title].dropbox, ''] : [''];
        setForm({ ...form, inputs: updatedInputs });
    };

    const handleRemoveOption = (title, optionIndex) => {
        const updatedInputs = { ...form.inputs };
        updatedInputs[title].dropbox.splice(optionIndex, 1);
        setForm({ ...form, inputs: updatedInputs });
    };

    const handleOptionChange = (title, optionIndex, value) => {
        const updatedInputs = { ...form.inputs };
        updatedInputs[title].dropbox[optionIndex] = value;
        setForm({ ...form, inputs: updatedInputs });
    };

    const handleRemoveInput = (title) => {
        const updatedInputs = { ...form.inputs };
        delete updatedInputs[title];
        setForm({
            ...form,
            inputs: updatedInputs
        });
    };

    const handleSelectInputOption = (type) => {
        const newInputId = uuidv4(); // Genera un ID único
        const newOrder = Object.keys(form.inputs).length;
        setForm({
            ...form,
            inputs: {
                ...form.inputs,
                [newInputId]: {
                    title: `Input ${Object.keys(form.inputs).length + 1}`,
                    id: newInputId,
                    name: '',
                    order: newOrder,
                    type: type
                }
            }
        });
        setIsDropdownOpen(false); // Cierra el menú desplegable después de añadir
    };


    const handleSizeChange = (id, isChecked) => {
        const updatedInputs = { ...form.inputs };
        if (isChecked) {
            updatedInputs[id].size = "LARGE";
        } else {
            delete updatedInputs[id].size; // Remueve la propiedad si el checkbox no está seleccionado
        }
        setForm({
            ...form,
            inputs: updatedInputs
        });
    };


    // Helper function to filter inputs and exclude 'order'
    const getFilteredInputs = (inputs) => {
        return Object.fromEntries(
            Object.entries(inputs).map(([title, input]) => {
                const { order, ...filteredInput } = input;
                return [title, filteredInput];
            })
        );
    };

    return (
        <div className='formCreatorContainer'>
            {/* <Typography variant="h3" sx={{ marginBottom: 3 }}>Crear Formulario Personalizado</Typography> */}
            <div className='sections'>
                <div className="left-section">
                    <form onSubmit={handleFormSubmit}>
                        <IconPicker
                            selectedIcon={form.icon}
                            onSelect={(iconURL) => setForm(prev => ({ ...prev, icon: iconURL }))}
                        />
                        <FormControl sx={{ width: 100, marginRight: 2, marginTop: 1 }}>
                            <InputLabel htmlFor="export">Export</InputLabel>
                            <Select
                                labelId="export-label"
                                id="export"
                                name="export"
                                value={form.export}
                                onChange={handleInputChange}
                            >
                                <MenuItem value="">
                                    <em>Seleccione...</em>
                                </MenuItem>
                                <MenuItem value="csv">CSV</MenuItem>
                                <MenuItem value="pdf">PDF</MenuItem>
                                <MenuItem value="foto">Foto</MenuItem>
                            </Select>
                        </FormControl>
                        {errors.export && <Typography color="error">{errors.export}</Typography>}
                        <FormControl sx={{ width: 200, marginRight: 2, marginTop: 1 }}>

                            <InputLabel htmlFor="client-selection">Cliente</InputLabel>
                            <Select
                                value={selectedClient}
                                onChange={(e) => {
                                    setSelectedClient(e.target.value);
                                    if (e.target.value === 'all') {
                                        setForm(prev => ({ ...prev, cliente: clients.map(client => client.id) }));
                                    } else {
                                        setForm(prev => ({ ...prev, cliente: [e.target.value] }));
                                    }
                                }}
                            >
                                <MenuItem value="all">Todos</MenuItem>
                                {clients.map(client => (
                                    <MenuItem key={client.id} value={client.id}>{client.nombre}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <TextField
                            label="Nombre del Formulario"
                            id="name"
                            name="name"
                            value={form.name || ''}
                            onChange={handleInputChange}
                            sx={{ marginTop: 1 }}
                        />
                        {errors.name && <Typography color="error">{errors.name}</Typography>}

                        <Button
                            type="button"
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            className="action-button"
                            sx={{ marginTop: 1 }}
                        >
                            Añadir Input
                        </Button>

                        {/* Opciones desplegadas para seleccionar el tipo de input */}
                        {isDropdownOpen && (
                            <div className="input-options">
                                <Button onClick={() => handleSelectInputOption('TEXT')}>Texto</Button>
                                <Button onClick={() => handleSelectInputOption('EMAIL')}>Correo electrónico</Button>
                                <Button onClick={() => handleSelectInputOption('DATE')}>Fecha</Button>
                                <Button onClick={() => handleSelectInputOption('URL')}>URL</Button>
                                <Button onClick={() => handleSelectInputOption('DATE_TIME')}>Fecha y hora</Button>
                                <Button onClick={() => handleSelectInputOption('NUMBER')}>Número</Button>
                                <Button onClick={() => handleSelectInputOption('UPLOAD_FILE')}>Adjuntar archivos</Button>
                                <Button onClick={() => handleSelectInputOption('UPLOAD')}>Foto</Button>
                                <Button onClick={() => handleSelectInputOption('DROPBOX')}>Opción (dropbox)</Button>
                                <Button onClick={() => handleSelectInputOption('MULTI_DROPBOX')}>Opciones múltiples</Button>
                            </div>
                        )}
                        <Button type="submit" className="action-button" sx={{ marginTop: 1 }}>Actualizar Formulario</Button>
                        {Object.entries(form.inputs).map(([id, input]) => (
                            <div
                                key={id}
                                style={{
                                    border: selectedInput === id ? '2px solid blue' : 'none',
                                    padding: selectedInput === id ? '10px' : '0',
                                    margin: selectedInput === id ? '10px 0' : '0'
                                }}
                                onClick={() => handleInputClick(id)}
                            >
                                <div>
                                    {/* <Typography variant="h5" sx={{ marginBottom: 2, marginTop: 3 }}>Nuevo campo</Typography> */}

                                    <Typography variant="h6" sx={{ marginBottom: 2, marginTop: 3 }}>

                                        <TextField
                                            label="Nombre del campo"
                                            type="text"
                                            value={input.title || ''}
                                            onChange={(e) => handleTitleChange(id, e.target.value)}
                                        />

                                        <Button
                                            type="button"
                                            className="remove-input-button"
                                            onClick={() => handleRemoveInput(id)}
                                        >
                                            Eliminar Input
                                        </Button>
                                    </Typography>
                                </div>


                                <FormControl>
                                    <InputLabel htmlFor="type">Tipo</InputLabel>
                                    <Select
                                        label="Tipo"
                                        id={`type${id}`}
                                        value={input.type}
                                        onChange={(e) => handleInputUpdate(id, { type: e.target.value })}
                                    >
                                        <MenuItem value="TEXT">Texto</MenuItem>
                                        <MenuItem value="EMAIL">Correo electrónico</MenuItem>
                                        <MenuItem value="DATE">Fecha</MenuItem>
                                        <MenuItem value="URL">URL</MenuItem>
                                        <MenuItem value="DATE_TIME">Fecha y hora</MenuItem>
                                        <MenuItem value="NUMBER">Número</MenuItem>
                                        <MenuItem value="UPLOAD_FILE">Adjuntar archivos</MenuItem>
                                        <MenuItem value="UPLOAD">Foto</MenuItem>
                                        <MenuItem value="DROPBOX">Opción (dropbox)</MenuItem>
                                        <MenuItem value="MULTI_DROPBOX">Opciones múltiples</MenuItem>
                                    </Select>
                                </FormControl>
                                {input.type === 'TEXT' && (
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={input.size === "LARGE"}
                                                    onChange={(e) => handleSizeChange(id, e.target.checked, 'LARGE')}
                                                    name="sizeLargeCheckbox"
                                                />
                                            }
                                            label="Tamaño grande"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={input.size === "SHORT"}
                                                    onChange={(e) => handleSizeChange(id, e.target.checked, 'SHORT')}
                                                    name="sizeShortCheckbox"
                                                />
                                            }
                                            label="Tamaño corto"
                                        />
                                    </div>
                                )}

                                {input.type === 'DROPBOX' && (
                                    <div>
                                        <Typography>Opciones:</Typography>
                                        <ul>
                                            {input.dropbox &&
                                                input.dropbox.map((option, optionIndex) => (
                                                    <li key={optionIndex}>
                                                        <TextField
                                                            type="text"
                                                            value={option || ''}
                                                            onChange={(e) => handleOptionChange(id, optionIndex, e.target.value)}
                                                            className="input-field"
                                                        />
                                                        <Button type="button" onClick={() => handleRemoveOption(id, optionIndex)}>
                                                            Eliminar
                                                        </Button>
                                                    </li>
                                                ))}
                                        </ul>
                                        <Button type="button" onClick={() => handleAddOption(id)}>
                                            Agregar Opción
                                        </Button>
                                    </div>
                                )}
                                {input.type === 'MULTI_DROPBOX' && (
                                    <div>
                                        <Typography>Opciones:</Typography>
                                        <ul>
                                            {input.dropbox &&
                                                input.dropbox.map((option, optionIndex) => (
                                                    <li key={optionIndex}>
                                                        <TextField
                                                            type="text"
                                                            value={option || ''}
                                                            onChange={(e) => handleOptionChange(id, optionIndex, e.target.value)}
                                                            className="input-field"
                                                        />
                                                        <Button type="button" onClick={() => handleRemoveOption(id, optionIndex)}>
                                                            Eliminar
                                                        </Button>
                                                    </li>
                                                ))}
                                        </ul>
                                        <Button type="button" onClick={() => handleAddOption(id)}>
                                            Agregar Opción
                                        </Button>
                                    </div>
                                )}
                                {errors[`${id}_title`] && <Typography color="error">{errors[`${id}_title`]}</Typography>}
                                {errors[`${id}_name`] && <Typography color="error">{errors[`${id}_name`]}</Typography>}
                                {errors[`${id}_options`] && <Typography color="error">{errors[`${id}_options`]}</Typography>}
                            </div>
                        ))}


                    </form>
                </div>
                <div className="right-section">
                    <FormPreview formTitle={form.name} formInputs={getFilteredInputs(form.inputs)} onInputClick={handleInputClick} />

                </div>
            </div>
            <Dialog
                open={openSuccessDialog}
                onClose={() => setOpenSuccessDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Formulario creado"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        El formulario se ha actualizado exitosamente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSuccessDialog(false)} color="primary">
                        Entendido
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default FormUpdate;
