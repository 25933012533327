import React, { useContext } from 'react';
import EditAdmin from '../../components/admin/EditAdmin';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import "./user.scss";
import { useSidebarState } from '../../components/sidebar/SidebarStateContext';
import { useParams } from 'react-router-dom';

const EditAdminContainer = () => {
  const { collapsed } = useSidebarState();
  const { adminId } = useParams();
  
  return (
    <div className={`user ${collapsed ? 'collapsed' : ''}`}>
      <Sidebar />
      <div className={`userContainer ${collapsed ? 'collapsed' : ''}`}>
        <Navbar />
        <EditAdmin />
      </div>
    </div>
  );
};

export default EditAdminContainer;
