import {
    collection,
    query,
    where,
    getDocs,
    addDoc,
    updateDoc,
    doc,
    arrayUnion,
    limit,
    getDoc
  } from 'firebase/firestore';
  import { db, auth } from '../../firebase';

export const fetchClientNames = async (clientIds, currentService) => {
    const clientNames = [];
    for (const clientId of clientIds) {
        const clientRef = doc(db, "servicios", currentService.id, "clientes", clientId);
        const clientSnap = await getDoc(clientRef);
        if (clientSnap.exists()) {
            clientNames.push(clientSnap.data().nombre); // Asume que el campo se llama 'nombre'
        }
    }
    return clientNames;
};