import { useContext, useState } from "react";
import "./login.scss";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext"
import { collection, getDocs, where, query } from "firebase/firestore";
import { uploadErrorLogToFirestore } from "../../utils/logUtils";
import logoUrl from "../../images/logoScreen.png"

/**
 * Componente de la página de inicio de sesión.
 * 
 * @returns {JSX.Element} Elemento JSX que representa la página de inicio de sesión.
 */
const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isSending, setIsSending] = useState(false);


  const { dispatch } = useContext(AuthContext)

  /**
 * Maneja el evento de inicio de sesión.
 * 
 * @param {Event} e Evento del formulario de inicio de sesión.
 */
  const handleLogin = (e) => {
    e.preventDefault();

    console.log("Attempting login with email:", email);

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        console.log("User logged in:", user);

        const q = query(collection(db, 'servicios'), where('admins', 'array-contains', email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.log("No matching service found for email:", email);
          setError(true);
          return;
        }

        const servicio = querySnapshot.docs[0].data();
        console.log("Service found:", servicio);

        dispatch({ type: "LOGIN", payload: user });
        window.location.href = '/';
      })
      .catch((error) => {
        setError(true);
        console.log("Login error:", error);
        uploadErrorLogToFirestore("Login error: ", error)
      });
  };

  /**
 * Maneja el proceso de restablecimiento de contraseña.
 * Si el usuario ha introducido un correo electrónico, envía un correo
 * con instrucciones para restablecer la contraseña.
 * Si ocurre un error durante el envío, se notifica al usuario.
 *
 * @function
 */
  const handleResetPassword = async () => {
    if (!email) {
      alert("Por favor, introduce tu correo electrónico.");
      return;
    }
    
    sendPasswordResetEmail(auth, email);
    alert("Se ha enviado un correo para restablecer tu contraseña. Por favor, revisa tu bandeja de entrada.");
  };

  const handleForgotPasswordClick = () => {
    // Cambia el estado para mostrar el formulario de recuperación de contraseña
    setIsResettingPassword(true);
  };

  const handleBackToLogin = () => {
    // Cambia el estado para volver al formulario de inicio de sesión
    setIsResettingPassword(false);
  };

  return (
    <div className="login">
      <div className="branding">
        <img src={logoUrl} alt="Logo" className="branding-logo" />
        <span className="branding-title">S E N T R Y</span>
      </div>
      <div className="loginContainer">
        <h2 className="loginTitle">{isResettingPassword ? "Cambiar contraseña" : "Inicia sesión"}</h2>

        <form onSubmit={isResettingPassword ? handleResetPassword : handleLogin} className="loginForm">
          <input
            type="email"
            placeholder="Correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
            className="loginInput"
            data-testid="email-input"
          />
          {!isResettingPassword && (
            <input
              type="password"
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
              className="loginInput"
              data-testid="password-input"
            />
          )}

          <button type="submit" className="loginButton">{isResettingPassword ? "Recuperar" : "Iniciar Sesión"}</button>
        </form>

        {isResettingPassword ? (
          <p className="forgotPassword" onClick={handleBackToLogin}>
            Volver a la pantalla de inicio de sesión
          </p>
        ) : (
          <p className="forgotPassword" onClick={handleForgotPasswordClick}>
            ¿Has olvidado tu contraseña?
          </p>
        )}
      </div>

    </div>
  );
};

export default Login;