import "./table.scss";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from '../../firebase';
import { collection, doc, setDoc, addDoc, updateDoc, deleteDoc, getDoc, getDocs, where, query, orderBy, limit, select } from "firebase/firestore";
import JSZip from 'jszip';
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
    getFolderName, downloadSelectedDocumentsAsPDF, capitalizeFirstLetter,
    downloadSelectedPhotos, formatDateForFolder, downloadSelectedCSV,
    isWithinLastNDays, formatDate, applyDateFilter, DownloadModal
} from './documentUtils';
import { uploadLogToFirestore, uploadErrorLogToFirestore } from '../../utils/logUtils.js'
import Bowser from "bowser";
import { ActionButtons, Table, FilterBar, DateFilterDropdown } from './ReportList.js'
import { fetchClientNames } from "./documentService.js"

/**
 * Componente List.
 * @returns {JSX.Element} - Elemento de React que representa la lista de documentos.
 */
const List = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isSafari = browser.getBrowserName() === "Safari";

    const [rows, setRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [allRowsSelected, setAllRowsSelected] = useState(false);
    const [filterDate, setFilterDate] = useState("");
    const [filterDateRegistro, setFilterDateRegistro] = useState("");
    const [filterVigilante, setFilterVigilante] = useState("");
    const [documentsLoaded, setDocumentsLoaded] = useState(true);
    const [searchTienda, setSearchTienda] = useState("");
    const [nombresLoaded, setNombresLoaded] = useState(false);
    const [deletedRows, setDeletedRows] = useState([]);
    const [dateFilter, setDateFilter] = useState("all"); // "all" or "last3Days"
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadFeedback, setDownloadFeedback] = useState(null);
    const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

    const { tipVS } = useParams();
    const { currentUser, currentService, adminData } = useContext(AuthContext);

    /**
 * Recupera los documentos del servicio actual.
 */
    useEffect(() => {
        const fetchData = async () => {
            // Verificar si se ha cargado correctamente antes de marcar como cargado
            if (!rows.some(row => row.nombre)) {
                setNombresLoaded(false);
            }
            if (rows.length > 0 && !nombresLoaded) {
                await populateNombre();
            }
        };

        fetchData();
    }, [rows, nombresLoaded]);


    useEffect(() => {
        fetchDocuments().then(() => {
            // Marcar que los documentos se han cargado
            setDocumentsLoaded(true);
        });
    }, [tipVS]);

    // Restaurar los valores desde localStorage al cargar la pestaña
    useEffect(() => {
        const persistedValues = JSON.parse(localStorage.getItem('filterValues'));

        if (persistedValues) {
            setSearchTerm(persistedValues.searchTerm);
            setFilterDate(persistedValues.filterDate);
            setFilterDateRegistro(persistedValues.filterDateRegistro);
            setFilterVigilante(persistedValues.filterVigilante);
            setSearchTienda(persistedValues.searchTienda);
        }
    }, []);

    // Guardar los valores en localStorage antes de cambiar de pestaña
    useEffect(() => {
        const valuesToPersist = {
            searchTerm,
            filterDate,
            filterDateRegistro,
            filterVigilante,
            searchTienda
        };
        localStorage.setItem('filterValues', JSON.stringify(valuesToPersist));
    }, [filterDate, filterDateRegistro, filterVigilante, searchTienda, searchTerm]);

    const fetchDocuments = async () => {
        try {
            if (currentService.id && adminData) {
                const clientNames = await fetchClientNames(adminData.clientes, currentService);

                const documentsRef = collection(db, "servicios", currentService.id, "documents");

                const documentsSnapshot = await getDocs(documentsRef);

                const documents = documentsSnapshot.docs.map((doc) => {
                    const documentData = doc.data();
                    // Acceder a los campos 'seconds' y 'nanoseconds' del objeto 'fecha'
                    const timestamp = documentData.fecha;
                    if (timestamp) {
                        const seconds = timestamp.seconds;
                        const nanoseconds = timestamp.nanoseconds;
                        // Construir la fecha a partir de los campos 'seconds' y 'nanoseconds'
                        const date = new Date(seconds * 1000 + nanoseconds / 1000000);

                        // Agregar la hora a la fecha
                        documentData.timestamp = date.getTime(); // Agregar el timeStamp para la ordenación
                    }
                    return { id: doc.id, ...documentData };
                }).filter(doc => clientNames.includes(doc.cliente) && !doc.borrado); // Filtrar documentos borrados y nombre de cliente

                // Ordenar los documentos por fecha en orden descendente
                const sortedDocuments = documents.sort((a, b) => b.timestamp - a.timestamp);

                // Formatear las fechas después de ordenar
                const formattedDocuments = sortedDocuments.map((document) => {
                    const date = new Date(document.timestamp);
                    const dia = date.getDate();
                    const mes = date.getMonth() + 1;
                    const año = date.getFullYear();
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const seconds = date.getSeconds();
                    const formattedTime = `${hours}:${minutes}:${seconds}`;
                    const fechaFormateada = `${dia}/${mes}/${año} ${formattedTime}`;
                    return { ...document, fecha: fechaFormateada };
                });

                setRows(formattedDocuments);

                return currentService.id;
            }

        } catch (error) {
            console.error('Error al obtener los documentos:', error);
            uploadErrorLogToFirestore('Error al obtener los documentos: ' + error);
        }
    };

    /**
      * Recupera y agrega el nombre del vigilante a cada documento.
      */
    const populateNombre = async () => {
        if (nombresLoaded) return;

        try {
            const serviciosRef = collection(db, 'servicios');
            const q = query(serviciosRef, where('admins', 'array-contains', currentUser.email));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const servicioData = servicioDoc.data();
                const servicioId = servicioDoc.id;

                const documentsWithNombre = await Promise.all(
                    rows.map(async (document) => {
                        try {
                            if (!document.vigilante) {
                                console.log('Missing vigilante property in document:', document);
                                return document;
                            }

                            const vigilanteId = document.vigilante.replace('@gmail.com', '');

                            const vigilantesQuerySnapshot = await getDocs(query(collection(db, 'servicios', servicioId, 'vigilantes'), where('tipVS', '==', vigilanteId)));

                            if (!vigilantesQuerySnapshot.empty) {
                                const vigilanteData = vigilantesQuerySnapshot.docs[0].data();

                                const nombre = vigilanteData.nombre;
                                const apellidos = vigilanteData.apellidos;

                                const nombreCompleto = `${nombre} ${apellidos}`;
                                return { ...document, nombre: nombreCompleto };
                            } else {
                                return document;
                            }
                        } catch (error) {
                            console.error('Error en promesa individual:', error);
                            uploadErrorLogToFirestore('Error en promesa individual:', error)
                            return document;
                        }
                    })
                );

                setRows(documentsWithNombre);
                setNombresLoaded(true);
            }
        } catch (error) {
            console.error('Error al obtener los documentos:', error);
            uploadErrorLogToFirestore('Error en promesa individual:', error)
        }
    };


    /**
 * Maneja el cambio en el campo de búsqueda.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de búsqueda.
 */
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    /**
 * Maneja el cambio en la selección de todas las filas.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio de la selección de todas las filas.
 */
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
            // Marcar todas las filas como seleccionadas
            const allRowsIds = filteredRows.map((row) => row.id);
            setSelectedRows(allRowsIds);
            setAllRowsSelected(true);
        } else {
            // Desmarcar todas las filas
            setSelectedRows([]);
            setAllRowsSelected(false);
        }
    };

    /**
 * Maneja el cambio en el campo de filtro de fecha de subida.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de fecha de subida.
 */
    const handleFilterChange = (event) => {
        setFilterDate(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de fecha de suceso.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de fecha de suceso.
 */
    const handleFilterChangeDate = (event) => {
        setFilterDateRegistro(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de vigilante.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de vigilante.
 */
    const handleVigilanteFilterChange = (event) => {
        setFilterVigilante(event.target.value);
    };

    /**
 * Maneja el cambio en el campo de filtro de tienda.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio del campo de filtro de tienda.
 */
    const handleTiendaFilterChange = (event) => {
        setSearchTienda(event.target.value);
    };

    /**
 * Maneja la selección/deselección de una fila.
 * @param {React.ChangeEvent<HTMLInputElement>} event - Evento de cambio de selección de la fila.
 * @param {string} id - ID de la fila seleccionada/deseleccionada.
 */
    const handleRowSelection = (event, id) => {
        if (event.target.checked) {
            setSelectedRows([...selectedRows, id]);
        } else {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        }

        // Verificar si la fila se ha marcado como borrada
        const isDeleted = event.target.checked && deletedRows.includes(id);

        // Si la fila está marcada como borrada, eliminarla de la lista de borrados
        if (isDeleted) {
            setDeletedRows(deletedRows.filter((rowId) => rowId !== id));
        }
    };

    //TODO HACER ESTA PARTE PARA LOS DOCUMENTOS PERSONALIZADOS

    /**
    * Maneja la descarga de los documentos seleccionados.
    */
    const handleDownloadSelected = async () => {
        try {
            // Inicializar el progreso
            setDownloadProgress(0);
            setDownloadFeedback(null);
            setDownloadModalOpen(true);

            const selectedDocuments = rows.filter((row) => selectedRows.includes(row.id));

            const zip = new JSZip();
            const folder = zip.folder('documentos');

            const totalFiles = selectedDocuments.length;
            let downloadedFiles = 0;

            // Lógica para manejar exportaciones basadas en el tipo o en el campo "export"
            for (const document of selectedDocuments) {
                if (
                    document.documentTypeId === "legal" ||
                    document.documentTypeId === "ims" ||
                    document.documentTypeId === "112" ||
                    document.documentTypeId === "denuncia" ||
                    document.documentTypeId === "intervencion" ||
                    document.documentTypeId === "parte de trabajo"
                ) {
                    // Documentos de tipos específicos
                    // Lógica para manejar estos documentos
                    if (document.documentTypeId === "legal") {
                        // Lógica para documentos de tipo "legal"
                    } else if (document.documentTypeId === "ims") {
                        // Lógica para documentos de tipo "ims"
                    }
                    // Agregar más casos según sea necesario
                } else {
                    try {
                        // Hacer una llamada a Firebase para obtener información adicional
                        const formSnapshot = await getDoc(doc(db, 'servicios', currentService.id, 'forms', document.documentTypeId));

                        if (formSnapshot.exists()) {
                            const formData = formSnapshot.data();

                            // Aquí puedes acceder a la información adicional y realizar las acciones necesarias
                            console.log(formData.export);

                            if (Array.isArray(formData.export)) {
                                // Manejar múltiples tipos de exportación
                                for (const exportType of formData.export) {
                                    // Acciones específicas para cada tipo de exportación
                                    if (exportType === "pdf") {
                                        await downloadSelectedDocumentsAsPDF([document], folder, exportType);
                                    } else if (exportType === "csv") {
                                        downloadSelectedCSV([document], folder, exportType);
                                    } else if (exportType === "foto") {
                                        await downloadSelectedPhotos([document], folder, exportType);
                                    }
                                    // Agregar más acciones según sea necesario
                                }
                            }
                        } else {
                            console.error("El formulario no fue encontrado en la colección forms.");
                        }
                    } catch (error) {
                        console.error("Error al obtener información adicional desde Firebase:", error);
                    }
                }

                // Actualizar progreso
                downloadedFiles++;
                setDownloadProgress((downloadedFiles / totalFiles) * 100);
            }
            await downloadSelectedPhotos(
                selectedDocuments.filter(
                    (document) => document.documentTypeId === "legal" || document.documentTypeId === "ims"
                        || document.documentTypeId === "denuncia" || document.documentTypeId === "intervencion"
                ),
                folder
            );

            // Actualizar progreso
            downloadedFiles++;
            setDownloadProgress((downloadedFiles / totalFiles) * 100);

            downloadSelectedCSV(selectedDocuments, folder);

            // Actualizar progreso
            downloadedFiles++;
            setDownloadProgress((downloadedFiles / totalFiles) * 100);

            await downloadSelectedDocumentsAsPDF(selectedDocuments, folder);

            // Actualizar progreso
            downloadedFiles++;
            setDownloadProgress((downloadedFiles / totalFiles) * 100);

            // Agregar el campo 'downloaded: true' en la base de datos
            const currentTimeStamp = new Date();
            const updatedDocuments = selectedDocuments.map((document) => ({
                ...document,
                downloaded: true,
                downloadedTime: currentTimeStamp,
            }));

            // Antes de la descarga
            const uniqueRowIds = new Set(rows.map((row) => row.id));
            const filteredUniqueDocuments = updatedDocuments.filter((document) => !uniqueRowIds.has(document.id));

            // Actualizar las filas en el estado
            setRows([...rows, ...filteredUniqueDocuments]);

            // Agregar el campo 'downloaded: true' en la base de datos
            const servicioId = await fetchDocuments();
            const serviciosRef = collection(db, 'servicios');
            for (const document of updatedDocuments) {
                await updateDoc(doc(serviciosRef, servicioId, 'documents', document.id), {
                    downloaded: true,
                    downloadedTime: currentTimeStamp,
                });
            }

            zip.generateAsync({ type: "blob" }).then((content) => {
                // Descargar archivo ZIP
                const link = document.createElement("a");
                link.href = URL.createObjectURL(content);
                link.download = "documentos.zip";
                link.click();

                uploadLogToFirestore('documentos descargados: ' + link.href);
                // Limpiar la selección después de la descarga
                setSelectedRows([]);
                setDownloadFeedback("Descarga exitosa");
            });
        } catch (error) {
            // Mostrar mensaje de error en caso de fallo
            setDownloadFeedback("Error al descargar");
            uploadErrorLogToFirestore("Error al descargar")
        } finally {
            // Restablecer el progreso después de completar la descarga
            setDownloadProgress(0);
        }
    };

    const handleDeleteSelected = async () => {
        const currentTimeStamp = new Date();
        // Establecer el campo "borrado" en true para las filas seleccionadas
        const updatedDocuments = rows.map((row) => {
            if (selectedRows.includes(row.id)) {
                return {
                    ...row,
                    borrado: true,
                    borradoTime: currentTimeStamp,
                };
            }
            return row;
        });

        // Actualizar las filas en el estado
        setRows(updatedDocuments);

        // Actualizar la lista de filas borradas
        setDeletedRows([...deletedRows, ...selectedRows]);

        // Desmarcar todas las filas seleccionadas
        setSelectedRows([]);

        // Obtener el servicioId
        const servicioId = await fetchDocuments();

        // Actualizar los documentos en la base de datos
        const serviciosRef = collection(db, 'servicios');
        for (const document of updatedDocuments) {
            if (selectedRows.includes(document.id)) {
                await updateDoc(doc(serviciosRef, servicioId, 'documents', document.id), {
                    borrado: true,
                    borradoTime: currentTimeStamp,
                });
            }
        }
    };


    /**
     * Filtra las filas de documentos según los términos de búsqueda y los filtros seleccionados.
     * @returns {Array} - Filas de documentos filtrados.
     */
    const filteredRows = rows.filter((row) => {
        const fecha = row.fields?.FECHA ?? '';
        const nombre = row.nombre ?? '';
        const documentDate = formatDate(row.fecha);

        const isMatchingSearchTerm = row.documentTypeId?.toLowerCase().includes(searchTerm.toLowerCase()) ?? false;
        const isMatchingFecha = row.fecha?.toLowerCase().includes(filterDate.toLowerCase()) ?? false;
        const isMatchingFechaRegistro = fecha.toLowerCase().includes(filterDateRegistro.toLowerCase()) ?? false;
        const isMatchingTienda = !row.tienda || row.tienda?.toLowerCase().includes(searchTienda.toLowerCase());
        const isMatchingVigilante = nombre.toLowerCase().includes(filterVigilante.toLowerCase());
        const isNotDeleted = !deletedRows.includes(row.id);

        return (
            isMatchingSearchTerm &&
            isMatchingFecha &&
            isMatchingFechaRegistro &&
            isMatchingTienda &&
            isMatchingVigilante &&
            isNotDeleted &&
            applyDateFilter(row, documentDate, dateFilter)
        );
    });

    return (
        <div className="tableContainer">
            <h2 className="datatableTitle">Reportes</h2>
            <FilterBar
                searchTerm={searchTerm}
                handleSearchChange={handleSearchChange}
                filterDate={filterDate}
                handleFilterChange={handleFilterChange}
                filterDateRegistro={filterDateRegistro}
                handleFilterChangeDate={handleFilterChangeDate}
                searchTienda={searchTienda}
                handleTiendaFilterChange={handleTiendaFilterChange}
                filterVigilante={filterVigilante}
                handleVigilanteFilterChange={handleVigilanteFilterChange}
            />
            <DownloadModal
                isOpen={isDownloadModalOpen}
                onClose={() => setDownloadModalOpen(false)}
                progress={downloadProgress}
                feedback={downloadFeedback}
            />
            <div className="tableControlsRow">
                <ActionButtons
                    handleDownloadSelected={handleDownloadSelected}
                    selectedRows={selectedRows}
                    handleDeleteSelected={handleDeleteSelected}
                />
                <DateFilterDropdown dateFilter={dateFilter} setDateFilter={setDateFilter} />
            </div>
            <Table
                filteredRows={filteredRows}
                allRowsSelected={allRowsSelected}
                selectedRows={selectedRows}
                handleSelectAllRows={handleSelectAllRows}
                handleRowSelection={handleRowSelection}
                handleDeleteSelected={handleDeleteSelected}
            />
        </div>
    );
};

export default List;