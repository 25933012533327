import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc } from 'firebase/firestore';
import { AuthContext } from "../../context/AuthContext";
import { db } from '../../firebase';
import { Calendar } from 'react-big-calendar'; // Asegúrate de instalar este paquete
import './EditVigilante.scss';

const EditVigilante = () => {
    const { vigilanteId } = useParams();
    const { currentService, adminData } = useContext(AuthContext);
    const [vigilante, setVigilante] = useState(null);

    console.log(vigilanteId)

    useEffect(() => {
        const fetchVigilante = async () => {
            try {
                if (currentService && adminData && vigilanteId) {
                    const vigilanteDoc = doc(db, 'servicios', currentService.id, 'vigilantes', vigilanteId);
                    const vigilanteSnapshot = await getDoc(vigilanteDoc);
                    
                    if (vigilanteSnapshot.exists()) {
                        setVigilante(vigilanteSnapshot.data());
                    } 
                    else {
                        console.error('Vigilante no encontrado');
                    }
                }
            } catch (error) {
                console.error('Error al obtener datos del vigilante:', error);
            }
        };

        fetchVigilante();
    }, [currentService, vigilanteId]);

    if (!vigilante) {
        return <div>Cargando...</div>;
    }

    return (
        <div className="edit-vigilante-container">
            <div className="header">
                <h1>{vigilante.nombre}</h1>
                <p>{vigilante.tip}</p>
            </div>

            <div className="calendar">
                <Calendar />
            </div>
        </div>
    );
};

export default EditVigilante;
