import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { uploadLogToFirestore, uploadErrorLogToFirestore } from "../../utils/logUtils";
import { ImageUploader, ServiceDropdown, TiendaInput } from './utils/NewUtils';
import { fetchTiendas, fetchClientes, registerVigilante } from './utils/FirebaseUtils';
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import { fetchClientesPorAdministrativo, fetchClientesPorInspector, fetchTiendasPorCampoCliente } from "../../components/admin/firestoreService"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText
} from '@mui/material';


/**
 * Componente para crear una nueva entrada.
 *
 * @param {Object} inputs - Los campos de entrada para la nueva entrada.
 * @param {string} title - El título del formulario.
 * @returns {JSX.Element} - Componente New.
 */
const New = ({ inputs, title }) => {
  const [file, setFile] = useState("");
  const [data, setData] = useState({ tiendas: [] });
  const [per, setPerc] = useState(null);
  const [clientes, setClientes] = useState([]); // Estado para almacenar la lista de clientes
  const [selectedCliente, setSelectedCliente] = useState(""); // Estado para el cliente seleccionado
  const [tiendasCliente, setTiendasCliente] = useState([]); // Estado para las tiendas del cliente seleccionado
  const [tiendas, setTiendas] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(tiendas.map(tienda => tienda.numTienda.toString()));
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [isAllClientesSelected, setIsAllClientesSelected] = useState(false);
  const [isAllTiendasSelected, setIsAllTiendasSelected] = useState(false);

  const navigate = useNavigate();
  const { currentUser, adminData, currentService } = useContext(AuthContext);
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";


  useEffect(() => {
    const fetchData = async () => {
      try {
        const list = await fetchTiendas(currentUser.email);
        setTiendas(list);

        // Obtener la lista de clientes basada en el tipo de usuario y el servicio actual
        if (currentUser && currentService && adminData) {
          if (adminData?.tipoUsuario?.includes('administrativo')) {
            fetchClientesPorAdministrativo(adminData.id, currentService)
              .then(clientesFiltrados => {
                setClientes(clientesFiltrados);
              })
              .catch(console.error);
          } else if (adminData.tipoUsuario?.includes('inspector')) {
            fetchClientesPorInspector(adminData.id, currentService)
              .then(clientesFiltrados => {
                setClientes(clientesFiltrados);
              })
              .catch(console.error);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [currentUser, currentService, adminData]);


  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
          uploadErrorLogToFirestore(error)
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);


  /**
 * Maneja el cambio de los campos de entrada.
 *
 * @param {Object} e - Evento del campo de entrada.
 */
  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };


  // Manejador de cambio de cliente seleccionado
  const handleClienteChange = (e) => {
    const clientesSeleccionados = e.target.value;
    if (clientesSeleccionados.includes('all')) {
      setIsAllClientesSelected(!isAllClientesSelected);
      setSelectedClientes(isAllClientesSelected ? [] : clientes.map(c => c.id));
      // Actualizar también las tiendas según la selección
    } else {
      setIsAllClientesSelected(clientesSeleccionados.length === clientes.length);
      setSelectedClientes(clientesSeleccionados);
      // Filtrar y actualizar las tiendas basadas en los clientes seleccionados
    }

    // Filter the stores based on the selected clients
    const filteredTiendas = tiendas.filter((tienda) => {
      // Check if any of the selected clients match the clients associated with the store
      return clientesSeleccionados.some((selectedCliente) =>
        tienda?.cliente?.includes(selectedCliente)
      );
    });

    // Update the list of stores based on the selected clients
    setTiendasCliente(filteredTiendas);
  }

  const handleCheckboxChange = (event) => {
    const selectedValues = event.target.value;

    if (selectedValues.includes('allServices')) {
        setIsAllTiendasSelected(!isAllTiendasSelected);
        setSelectedOptions(isAllTiendasSelected ? [] : tiendas.map(t => t.numTienda.toString()));
        // Actualizar también data.tiendas
    } else {
        setIsAllTiendasSelected(selectedValues.length === tiendas.length);
        setSelectedOptions(selectedValues);
        // Actualizar data.tiendas según la selección
    }
};

  /**
 * Maneja el registro de la nueva entrada.
 *
 * @param {Object} e - Evento del formulario.
 */
  const handleAdd = async (e) => {
    e.preventDefault();

    // Check if there are duplicate stores
    const storeNumbers = data.tiendas.map((tienda) => tienda.numero);
    const uniqueStoreNumbers = new Set(storeNumbers);
    if (storeNumbers.length !== uniqueStoreNumbers.size) {
      window.alert("Registro fallido: Hay tiendas duplicadas");
      return;
    }

    // Check if there are no stores added
    if (storeNumbers.length === 0) {
      window.alert("Registro fallido: No se ha agregado ninguna tienda");
      return;
    }

    // Check if all selected store numbers are valid options
    const validStoreNumbers = tiendas.map((tienda) => tienda.numTienda);
    const selectedStoreNumbers = data.tiendas.map((tienda) => tienda.numero);
    const isValidStore = selectedStoreNumbers.every((number) =>
      validStoreNumbers.includes(number)
    );
    if (!isValidStore) {
      window.alert(
        "Registro fallido: El identificador de servicio seleccionado no es válido"
      );
      return;
    }

    // If there are no duplicate stores and all selected store numbers are valid, proceed to add the vigilante
    try {
      const result = await registerVigilante(data, currentUser.email);
      console.log(result)
      if (result && result.success) {
        uploadLogToFirestore("nuevo vigilante añadido con id: " + result.uid);
        navigate(-1);
      } else {
        uploadErrorLogToFirestore(result.error)
        throw new Error(result.error);
      }
    } catch (err) {
      console.log(err);
      uploadErrorLogToFirestore("error registrando vigilante: " + err.message);
      if (err.message.includes("password")) {
        window.alert(
          "Registro fallido: La contraseña debe tener al menos 6 caracteres"
        );
      } else if (err.message.includes("email-already-in-use")) {
        window.alert("Registro fallido: El vigilante ya ha sido registrado");
      } else {
        window.alert("Registro fallido: " + err.message);
      }
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <ImageUploader file={file} setFile={setFile} />
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <div className="inputPairs">
                  <FormControl className="formInput">
                    <InputLabel id="clientes-label">Clientes</InputLabel>
                    <Select
                      labelId="clientes-label"
                      id="clientes"
                      multiple
                      value={selectedClientes}
                      onChange={handleClienteChange}
                      label="Clientes"
                    >
                      <MenuItem value="all">
                        <Checkbox checked={isAllClientesSelected} />
                        <ListItemText primary="Seleccionar todos los clientes" />
                      </MenuItem>
                      {clientes.map((cliente) => (
                        <MenuItem key={cliente.id} value={cliente.id}>
                          {cliente.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl className="formInput">
                    <InputLabel id="servicios-label">Servicios</InputLabel>
                    <Select
                      labelId="servicios-label"
                      id="servicios"
                      multiple
                      value={selectedOptions}
                      onChange={handleCheckboxChange}
                      label="Servicios"
                    >
                      <MenuItem value="allServices">
                        <Checkbox checked={isAllTiendasSelected} />
                        <ListItemText primary="Seleccionar todos los servicios" />
                      </MenuItem>
                      {tiendasCliente.map((tienda) => (
                        <MenuItem key={tienda.numTienda} value={tienda.numTienda.toString()}>
                          {tienda.numTienda}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="formInput">
                <label>Nombre:</label>
                <input
                  id="nombre"
                  type="text"
                  placeholder="Nombre"
                  onChange={handleInput}
                  required
                />
              </div>
              <div className="formInput">
                <label>Apellidos:</label>
                <input
                  id="apellidos"
                  type="text"
                  placeholder="Apellidos"
                  onChange={handleInput}
                  required
                />
              </div>
              {/* Agrupa los campos de entrada en pares, excepto nombre y apellidos */}
              <div className="inputPairs">
                {inputs.map((input, index) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={input.placeholder}
                      onChange={handleInput}
                      {...(input.id === 'telefono' || input.id === 'email' ? { required: false } : {})}
                    />
                  </div>
                ))}
              </div>
              <div className="button-container">
                <button disabled={per !== null && per < 100} type="submit">
                  Registrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default New;