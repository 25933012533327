import React from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import customLocaleText from '../../datatable/LocaleText';

/**
 * Componente que muestra un DataGrid con las filas y columnas proporcionadas.
 *
 * @component
 * @param {Object} props - Propiedades del componente.
 * @param {Array<Object>} props.rows - Las filas que se mostrarán en el DataGrid.
 * @param {Array<Object>} props.columns - Las columnas que se mostrarán en el DataGrid.
 * @returns {JSX.Element} - React JSX que representa el componente DataGrid.
 */
const DataGridComponent = ({ rows, columns }) => {
    return (
        <DataGridPro
            rows={rows}
            columns={columns}
            pageSize={100}
            checkboxSelection
            localeText={customLocaleText}
            slots={{ toolbar: GridToolbar }}
        />
    );
};

export default DataGridComponent;
