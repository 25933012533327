import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState, useContext } from "react";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  getDocs,
  where,
  query,
  updateDoc,
  arrayUnion
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { uploadLogToFirestore, uploadErrorLogToFirestore } from "../../utils/logUtils";
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";
import { fetchAdministrativosAsignados, fetchInspectoresAsignados } from "./utils/FirebaseUtils";
import { comunidades, provincias } from "../../zoneData";
import noImage from '../../images/no-image.png';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

/**
 * Componente para crear una nueva tienda.
 *
 * @param {Object} inputs - Los campos de entrada para la tienda.
 * @param {string} title - El título del formulario.
 * @returns {JSX.Element} - Componente NewTienda.
 */
const NewClienteTienda = ({ inputs, title }) => {
  const { currentUser, currentService, adminData } = useContext(AuthContext);

  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const [isDuplicateNumTienda, setIsDuplicateNumTienda] = useState(false);
  const [inspectoresAsignados, setInspectoresAsignados] = useState([]);
  const [administrativosAsignados, setAdministrativosAsignados] = useState([]);
  const [selectedInspectores, setSelectedInspectores] = useState([]);
  const [selectedAdministrativos, setSelectedAdministrativos] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [adminProvincias, setAdminProvincias] = useState(adminData ? adminData.provincias || [] : []);
  const [adminComunidades, setAdminComunidades] = useState(adminData ? adminData.comunidades || [] : []);


  const navigate = useNavigate();
  const { clienteId, nombreCliente } = useParams(); // Obtiene el clienteId de la URL
  const { collapsed } = useSidebarState();
  const containerClass = collapsed ? "newContainer collapsed" : "newContainer";


  useEffect(() => {
    // Comprobar si currentService está disponible
    if (currentService) {
      const currentServiceId = currentService.id;

      // Obtener inspectores asignados
      fetchInspectoresAsignados(currentServiceId).then((inspectores) => {
        setInspectoresAsignados(inspectores);
      });

      // Obtener administrativos asignados
      fetchAdministrativosAsignados(currentServiceId).then((administrativos) => {
        setAdministrativosAsignados(administrativos);
      });
    }
  }, [currentService]);


  useEffect(() => {
    const uploadFile = () => {
      const name = new Date().getTime() + file.name;

      const storageRef = ref(storage, file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          uploadErrorLogToFirestore(error)
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);


  /**
 * Maneja el cambio de los campos de entrada.
 *
 * @param {Object} e - Evento del campo de entrada.
 */
  const handleInput = (e) => {
    const id = e.target.id || e.target.name; // Usar name como fallback si id no está disponible
    const isSelectMultiple = Array.isArray(e.target.value); // Verificar si es un Select múltiple
    const value = isSelectMultiple ? e.target.value : e.target.value;

    // Actualizar el estado de numTienda
    if (id === "numTienda") {
      setIsDuplicateNumTienda(false); // Reiniciar el estado de la comprobación
    }

    // Actualizar el estado según el ID del campo
    if (id === "inspectoresAsignados") {
      setSelectedInspectores(value);
      console.log(selectedInspectores)
    } else if (id === "administrativosAsignados") {
      setSelectedAdministrativos(value);
      console.log(selectedAdministrativos)
    } else if (['provincia', 'comunidad'].includes(id)) {
      // Solo actualizar si la provincia o comunidad está en las del admin
      if (adminProvincias.includes(value) || adminComunidades.includes(value)) {
        setData({ ...data, [id]: value });
      }
    }
    else {
      setData({ ...data, [id]: value });
    }
  };


  /**
 * Maneja el registro de la nueva tienda.
 *
 * @param {Object} e - Evento del formulario.
 */
  const handleAdd = async (e) => {
    e.preventDefault();

    try {
      // Referencia directa a la colección de tiendas del servicio actual
      const tiendasRef = collection(db, "servicios", currentService.id, "tiendas");

      // Verificar si el número de tienda ya está en uso
      const numTiendaQuery = query(tiendasRef, where("numTienda", "==", data.numTienda));
      const numTiendaSnapshot = await getDocs(numTiendaQuery);

      if (!numTiendaSnapshot.empty) {
        setIsDuplicateNumTienda(true);
        alert("El número de tienda ya está en uso. Por favor, elija otro.");
        return;
      }

      // Crear la nueva tienda
      const newTiendaRef = await addDoc(tiendasRef, {
        ...data,
        cliente: clienteId,
        nombreCliente: nombreCliente,
        inspectoresAsignados: selectedInspectores,
        administrativosAsignados: selectedAdministrativos,
        timeStamp: serverTimestamp(),
      });

      // ID de la nueva tienda
      const newTiendaId = newTiendaRef.id;

      // Actualizar documentos de inspectores y administrativos asignados
      for (const inspectorId of selectedInspectores) {
        const inspectorRef = doc(db, 'servicios', currentService.id, 'admins', inspectorId);
        await updateDoc(inspectorRef, {
          tiendas: arrayUnion(newTiendaId),
        });
      }

      for (const administrativoId of selectedAdministrativos) {
        const administrativoRef = doc(db, 'servicios', currentService.id, 'admins', administrativoId);
        await updateDoc(administrativoRef, {
          tiendas: arrayUnion(newTiendaId),
        });
      }

      uploadLogToFirestore('tienda añadida');
      navigate(-1);

    } catch (err) {
      uploadErrorLogToFirestore(err)
      console.log(err);
    }
  };


  const getInspectorNameById = (id) => {
    const inspector = inspectoresAsignados.find(ins => ins.id === id);
    return inspector ? inspector.apellido ? `${inspector.nombre} ${inspector.apellido}` : inspector.nombre : '';
  };

  const getAdministrativoNameById = (id) => {
    const administrativo = administrativosAsignados.find(adm => adm.id === id);
    return administrativo ? administrativo.apellido ? `${administrativo.nombre} ${administrativo.apellido}` : administrativo.nombre : '';
  };



  return (
    <div className="new">
      <Sidebar />
      <div className={containerClass}>
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : noImage
              }
              alt=""
            />
            <div className='upload-label'>
              <label htmlFor="file">
                <DriveFolderUploadOutlinedIcon className="icon" /> Subir imagen
              </label>
            </div>
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>
              <div className="inputPairs">
                {inputs.map((input) => (
                  <div className="formInput" key={input.id}>
                    <label>{input.label}</label>
                    {input.id === "inspectoresAsignados" && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="inspectoresAsignados-label">{input.label}</InputLabel>

                        <Select
                          id={input.id}
                          name="inspectoresAsignados"
                          multiple
                          value={selectedInspectores}
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id="select-inspectores" label="Inspectores" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((id) => (
                                <Chip key={id} label={getInspectorNameById(id)} />
                              ))}
                            </Box>
                          )}
                        >
                          {inspectoresAsignados.map((inspector) => (
                            <MenuItem key={inspector.id} value={inspector.id}>
                              {inspector.nombre} {inspector.apellido}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {input.id === "administrativosAsignados" && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="administrativosAsignados-label">{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name="administrativosAsignados"
                          multiple
                          value={selectedAdministrativos}
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id="select-administrativos" label="Administrativos" />}
                          renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((id) => (
                                <Chip key={id} label={getAdministrativoNameById(id)} />
                              ))}
                            </Box>
                          )}
                        >
                          {administrativosAsignados.map((administrativo) => (
                            <MenuItem key={administrativo.id} value={administrativo.id}>
                              {administrativo.nombre} {administrativo.apellido}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {['comunidad', 'provincia'].includes(input.id) && input.type === 'dropdown' && (
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id={`${input.id}-label`}>{input.label}</InputLabel>
                        <Select
                          id={input.id}
                          name={input.id}
                          value={data[input.id]}
                          onChange={(e) => handleInput(e)}
                          input={<OutlinedInput id={`select-${input.id}`} label={input.label} />}
                        >
                          {input.id === 'comunidad' ? (
                            adminComunidades.map((comunidad) => (
                              <MenuItem key={comunidad} value={comunidad}>
                                {comunidad}
                              </MenuItem>
                            ))
                          ) : (
                            adminProvincias.map((provincia) => (
                              <MenuItem key={provincia} value={provincia}>
                                {provincia}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    )}
                    {input.id !== "inspectoresAsignados" &&
                      input.id !== "administrativosAsignados" &&
                      input.type !== "dropdown" && (
                        <input
                          id={input.id}
                          type={input.type}
                          placeholder={input.placeholder}
                          onChange={handleInput}
                        />
                      )}
                  </div>
                ))}
              </div>
              <div className="button-container">
                <button disabled={per !== null && per < 100} type="submit">
                  Registrar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewClienteTienda;