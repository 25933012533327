import React, { useContext, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, query, where, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import { signInWithEmailAndPassword } from "firebase/auth";
import { uploadLogToFirestore, uploadErrorLogToFirestore } from "../../utils/logUtils";
import './update-servicio.scss'

/**
 * Componente para actualizar un servicio.
 *
 * @returns {JSX.Element} - Componente UpdateServicio.
 */
const UpdateServicio = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [servicio, setServicio] = useState(null);
    const [nombre, setNombre] = useState("");
    const [adminEmail, setAdminEmail] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserLoggedIn = async () => {
            auth.onAuthStateChanged((user) => {
                if (user) {
                    setLoggedIn(true);
                    setUser(user);
                    getServicio(user.uid);
                } else {
                    setLoggedIn(false);
                    setUser(null);
                    setServicio(null);
                }
            });
        };

        checkUserLoggedIn();
    }, []);

    /**
  * Obtiene los datos del servicio asociado al usuario.
  *
  * @param {string} userId - ID del usuario.
  */
    const getServicio = async (userId) => {
        try {
            const q = query(collection(db, "servicios"), where("userId", "==", userId));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                // Obtener el primer documento de la consulta (suponiendo que solo haya uno)
                const servicioDoc = querySnapshot.docs[0];

                setServicio(servicioDoc.data());
                setNombre(servicioDoc.data().nombre);
            } else {
                console.log("El servicio no existe.");
                uploadLogToFirestore("El servicio no existe.")
            }
        } catch (error) {
            uploadErrorLogToFirestore("Error al obtener el servicio:", error)
            console.log("Error al obtener el servicio:", error);
        }
    };

    /**
 * Maneja la actualización del nombre del servicio.
 *
 * @param {Object} e - Evento del formulario.
 */
    const handleUpdateNombre = async (e) => {
        e.preventDefault();

        try {
            const q = query(collection(db, "servicios"), where("userId", "==", user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];

                await updateDoc(servicioDoc.ref, {
                    nombre: nombre,
                });

                console.log("Nombre del servicio actualizado correctamente.");
                uploadLogToFirestore("Nombre del servicio actualizado correctamente.")
            } else {
                console.log("El servicio no existe.");
            }
        } catch (error) {
            console.log("Error al actualizar el nombre del servicio:", error);
            uploadLogToFirestore("Error al actualizar el nombre del servicio: " + error)
        }
    };

    /**
     * Maneja la adición de un administrador al servicio.
     *
     * @param {Object} e - Evento del formulario.
     */
    const handleAddAdmin = async (e) => {
        e.preventDefault();

        try {
            const q = query(collection(db, "servicios"), where("userId", "==", user.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const servicioDoc = querySnapshot.docs[0];
                const admins = servicioDoc.data().admins || [];
                const updatedAdmins = [...admins, adminEmail];

                await updateDoc(servicioDoc.ref, {
                    admins: updatedAdmins,
                });

                console.log("Administrador añadido correctamente.");
                uploadLogToFirestore("Administrador añadido correctamente.");
                setAdminEmail("");
            } else {
                console.log("El servicio no existe.");
                uploadErrorLogToFirestore("Error al añadir el administrador:", error)
            }
        } catch (error) {
            console.log("Error al añadir el administrador:", error);
            uploadErrorLogToFirestore("Error al añadir el administrador:", error)
        }
    };

    /**
     * Maneja el cierre de sesión del usuario.
     */
    const handleLogout = async () => {
        try {
            await auth.signOut();
            setLoggedIn(false);
            setUser(null);
        } catch (error) {
            uploadErrorLogToFirestore("Error al cerrar sesión:", error)
            console.log("Error al cerrar sesión:", error);
        }
    };

    const { dispatch } = useContext(AuthContext)

    /**
 * Maneja el inicio de sesión del usuario.
 *
 * @param {Object} e - Evento del formulario.
 */
    const handleLogin = (e) => {
        e.preventDefault();


        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {

                // Signed in
                const user = userCredential.user;
                dispatch({ type: "LOGIN", payload: user })
            })
            .catch((error) => {
                uploadErrorLogToFirestore(error)
                setError(true);
            });
    };

    if (!loggedIn) {
        return (
            <div>
                <h1>No ha iniciado sesión.</h1>
                <form onSubmit={handleLogin}>
                    <input
                        type="email"
                        placeholder="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="submit">Login</button>
                    {error && <span>Wrong email or password!</span>}
                    <Link to="/">Volver</Link>
                </form>
            </div>
        );
    }

    if (!servicio) {
        return (
            <div>
                <h1>Bienvenido</h1>
                <button onClick={handleLogout}>Cerrar sesión</button>
                <p>El usuario no tiene ningún servicio.</p>
                <Link to="/">Volver</Link>
            </div>
        );
    }

    return (
        <div className="update-servicio">
            <div className="top-right">
                <h1>Bienvenido {user ? user.email : ""}</h1>

                <button className="blue-button" onClick={handleLogout}>
                    Cerrar sesión
                </button>

                <Link className="volver-link" to="/">
                    Volver
                </Link>
            </div>
            <div className="datos-servicio">
                <h2>Datos del servicio</h2>
                <form onSubmit={handleUpdateNombre}>
                    <label>
                        Nombre del servicio:
                        <input
                            type="text"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                    </label>
                    <button className="blue-button" type="submit">
                        Actualizar nombre
                    </button>
                </form>
            </div>



            <div className="add-admin">
                <h2>Añadir administrador</h2>
                <form onSubmit={handleAddAdmin}>
                    <label>
                        Correo electrónico del administrador:
                        <input
                            type="email"
                            value={adminEmail}
                            onChange={(e) => setAdminEmail(e.target.value)}
                        />
                    </label>
                    <button className="blue-button" type="submit">
                        Añadir administrador
                    </button>
                </form>
                <div className="admins-servicio">
                    <h2>Admins del servicio</h2>
                    <ul>
                        {servicio.admins ? (
                            servicio.admins.map((admin, index) => (
                                <li key={index}>{admin}</li>
                            ))
                        ) : (
                            <li>No hay admins disponibles</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default UpdateServicio;
