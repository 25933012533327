import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../../firebase"

const NotificationView = () => {
  const [notifications, setNotifications] = useState([]);
  const { currentService } = useContext(AuthContext);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (currentService) {
        const servicioId = currentService.id;
        const notificationsCollection = collection(db, 'servicios', servicioId, 'notifications');

        try {
          const querySnapshot = await getDocs(notificationsCollection);

          const notificationsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));

          setNotifications(notificationsData);
        } catch (error) {
          console.error('Error al obtener notificaciones:', error);
        }
      }
    };

    fetchNotifications();
  }, [currentService]);

  return (
    <div class="notifications-container">
      <div class="notifications">
        <ul>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <li key={notification.id} class="notification-item">
                <div className='li-item'>
                  <strong>Fecha de envío:</strong> <span class="date">{notification.dateSent}</span>
                </div>
                <div className='li-item'>
                  <strong>Título:</strong> {notification.title}
                </div>
                <div className='li-item'>
                  <strong>Destino:</strong> <span class="destination">{notification.to}</span>
                </div>
                <div className='li-item'>
                  <div>
                    <strong>Mensaje de la notificación:</strong>
                  </div>
                </div>
                <div className='li-item'>
                  {notification.body}
                </div>
              </li>
            ))
          ) : (
            <p>No hay notificaciones disponibles.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default NotificationView;
