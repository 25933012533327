import noImage from '../../../images/no-image.png'
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState } from "react";
import "../new.scss"

export const ImageUploader = ({ file, setFile }) => {
    return (
        <div className="left">
            <img src={file ? URL.createObjectURL(file) : noImage} alt="" />
            <label htmlFor="file">
                <div className='upload-label'>
                    <DriveFolderUploadOutlinedIcon className="icon" /> Subir imagen
                </div>
            </label>
            <input
                type="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: "none" }}
            />
        </div>
    );
};

export const ServiceDropdown = ({ tiendas, selectedOptions, handleCheckboxChange, selectedCliente }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleServiceSelection = (value, isChecked) => {
        handleCheckboxChange({
            target: {
                value,
                checked: isChecked,
            },
        });
    };

    return (
        <div className="dropdown">
            <div className='fake-dropdown' onClick={toggleDropdown}>
                Agregar Servicio <strong>&#709;</strong>
            </div>
            {dropdownOpen && (
                <div className="dropdown-content">
                    <div className="dropdown-checkbox">
                        <input
                            className='dropdown-input'
                            type="checkbox"
                            id="allServices"
                            value="allServices"
                            checked={selectedOptions.includes("allServices")}
                            onChange={() => handleServiceSelection("allServices", !selectedOptions.includes("allServices"))}
                        />
                        <label htmlFor="allServices">Todos los servicios</label>
                    </div>
                    <div className="dropdown-checkbox">
                        <input
                            className='dropdown-input'
                            type="checkbox"
                            id="allClientServices"
                            value="allClientServices"
                            checked={selectedOptions.includes("allClientServices")}
                            onChange={() => handleServiceSelection("allClientServices", !selectedOptions.includes("allClientServices"))}
                        />
                        <label htmlFor="allClientServices">Todos los servicios del cliente</label>
                    </div>
                    {tiendas
                        .filter((tienda) => tienda.nombreCliente === selectedCliente)
                        .map((tienda) => (
                            <div key={tienda.id} className="dropdown-checkbox">
                                <input
                                    className='dropdown-input'
                                    type="checkbox"
                                    id={`service-${tienda.numTienda}`}
                                    value={tienda.numTienda}
                                    checked={selectedOptions.includes(tienda.numTienda.toString())}
                                    onChange={() => handleServiceSelection(tienda.numTienda.toString(), !selectedOptions.includes(tienda.numTienda.toString()))}
                                />
                                <label htmlFor={`service-${tienda.numTienda}`}>{tienda.numTienda} - {tienda.nombreServicio}</label>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};



export const TiendaInput = ({ tiendas, data, handleInputTienda, handleDeleteTienda }) => {
    console.log(data)
    return (
        <>
            {data.tiendas.map((tienda, index) => (
                <div key={tienda.id}>
                    <div className="formInputTienda">
                        <label>Seleccionar Servicio</label>
                        <br></br>
                        <select
                            value={tienda.numero}
                            onChange={(e) =>
                                handleInputTienda(index, "numero", e.target.value)
                            }
                        >
                            <option value="">Seleccionar servicio</option>
                            {tiendas.map((tienda) => (
                                <option key={tienda.id} value={tienda.numTienda}>
                                    {tienda.numTienda}
                                </option>
                            ))}
                        </select>
                        <button className="delete-tienda" onClick={() => handleDeleteTienda(index)}>
                            X
                        </button>
                    </div>
                </div>
            ))}
        </>
    );
};
