import React, { useEffect, useState, useContext } from "react";
import { collection, getDocs, getDoc, doc, query, where, setDoc, onSnapshot } from "firebase/firestore";
import { useParams, Link } from "react-router-dom";
import { db } from "../../firebase";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { tiendasColumns } from "../../datatablesource";
import { AuthContext } from "../../context/AuthContext";
import customLocaleText from './LocaleText.js'
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import ArrayCellRenderer from "../../ArrayCellRenderer.js";

/**
 * Componente para mostrar una tabla de tiendas de un cliente.
 * Permite filtrar y realizar acciones como eliminar y actualizar tiendas.
 *
 * @returns {JSX.Element} - Componente DatatableTiendasCliente.
 */
const DatatableTiendasCliente = () => {
  const { clienteId, nombreCliente } = useParams(); // Obtiene el clienteId de la URL

  const [tiendas, setTiendas] = useState([]);
  const [filter, setFilter] = useState("");
  const [clienteImg, setClienteImg] = useState(""); // Agregamos estado para la imagen del cliente
  const [showDeleted, setShowDeleted] = useState(false);
  const [recoveredItems, setRecoveredItems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]); // Nuevo estado para almacenar las filas seleccionadas
  const [emails, setEmails] = useState({}); // Aquí se almacenarán los correos por ID

  const { currentService, adminData } = useContext(AuthContext)

  /**
  * Función para obtener y cargar los datos de las tiendas del cliente y la imagen del cliente.
  */
  useEffect(() => {
    const fetchData = async () => {
      if (currentService.id) {
        try {
          // Filtrar las tiendas basándose en los ID de tiendas asignadas en adminData
          const tiendasIds = adminData.tiendas; // Suponiendo que adminData.tiendas es un array de ID de tiendas
          const tiendasQuery = query(
            collection(db, "servicios", currentService.id, "tiendas"),
            where("__name__", "in", tiendasIds),
            where("cliente", "==", clienteId)
          );
          const tiendasSnapshot = await getDocs(tiendasQuery);

          const tiendasData = tiendasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setTiendas(tiendasData);

          const clienteDoc = doc(db, "servicios", currentService.id, "clientes", clienteId);
          const clienteSnap = await getDoc(clienteDoc);
          if (clienteSnap.exists()) {
            setClienteImg(clienteSnap.data().img || "");
          }
        } catch (error) {
          console.error("Error al obtener las tiendas:", error);
        }
      } else {
        console.error("Servicio no definido en el contexto.");
      }
    };

    fetchData();
  }, [clienteId, adminData]);

  useEffect(() => {
    tiendas.forEach((tienda) => {
      if (tienda.administrativos || tienda.inspectores) {
        const ids = [...(tienda.administrativos || []), ...(tienda.inspectores || [])];
        ids.forEach(async (id) => {
          if (!emails[id]) {
            const email = await getEmailForId(id);
            setEmails((prevEmails) => ({ ...prevEmails, [id]: email }));
          }
        });
      }
    });
  }, [tiendas]);

  // Función para obtener el correo correspondiente a un ID de administrativo o inspector
  const getEmailForId = async (id) => {
    try {
      if (currentService && adminData) {
        const adminDocRef = doc(db, "servicios", currentService.id, "admins", id);
        const adminDocSnap = await getDoc(adminDocRef);

        if (adminDocSnap.exists()) {
          const adminData = adminDocSnap.data();
          return adminData.correo;
        }
      }
    }
    catch (error) {
      console.error("Error al obtener datos de administrador:", error);
    }

    return ""; // En caso de error o si no se encuentra el administrador
  };

  /**
 * Función para manejar la eliminación de una tienda.
 *
 * @param {string} id - ID de la tienda a eliminar.
 */
  const handleDelete = async (idsToDelete) => {
    if (!currentService.id) {
      console.error("Servicio no definido en el contexto.");
      return;
    }

    // Mostrar una alerta de confirmación
    const confirmDelete = window.confirm("¿Estás seguro de que deseas borrar los servicios seleccionados?");
    if (!confirmDelete) {
      return; // Si el usuario cancela, salir de la función
    }

    const idsArray = Array.isArray(idsToDelete) ? idsToDelete : [idsToDelete];

    const promises = idsArray.map(async (id) => {
      const tiendaRef = doc(db, "servicios", currentService.id, "tiendas", id);
      const tiendaDoc = await getDoc(tiendaRef);

      if (tiendaDoc.exists()) {
        const newStatus = tiendaDoc.data().borrado ? false : true;
        await setDoc(tiendaRef, { borrado: newStatus }, { merge: true });
        return { id, borrado: newStatus };
      }
      return null;
    });

    try {
      const results = await Promise.all(promises);
      // Filtra los resultados nulos y actualiza el estado
      const updatedTiendas = results.filter(result => result !== null);
      setTiendas(prevTiendas =>
        prevTiendas.map(tienda =>
          updatedTiendas.find(updated => updated.id === tienda.id)
            ? { ...tienda, borrado: updatedTiendas.find(updated => updated.id === tienda.id).borrado }
            : tienda
        )
      );
    } catch (err) {
      console.log(err);
    }
  };


  const handleShowDeletedChange = (event) => {
    setShowDeleted(event.target.checked);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Acción",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/tiendas/update/${encodeURIComponent(params.row.id)}`} style={{ textDecoration: "none" }}>
              <div className="actualizarButton">Actualizar</div>
            </Link>
            {params.row.borrado ? (
              <button className="recoverButton"
                onClick={() => handleDelete(Array.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : params.row.id)}
              >
                Recuperar
              </button>
            ) : (
              <DeleteIcon
                onClick={() => handleDelete(Array.isArray(selectedRows) && selectedRows.length > 0 ? selectedRows : params.row.id)}
                className="deleteIcon" />
            )}
          </div>
        );
      },
    },
  ];

  // Asegúrate de que onSelectionModelChange esté actualizando correctamente selectedRows
  const onSelectionModelChange = (newSelectionModel) => {
    setSelectedRows(newSelectionModel); // newSelectionModel debería ser un arreglo
  };


  const filteredData = tiendas.filter((row) => {
    const identifierValue = row.numTienda.toLowerCase(); // Cambia "numTienda" por el nombre real del campo que deseas filtrar
    return (
      identifierValue.includes(filter.toLowerCase()) &&
      (showDeleted ? row.borrado || recoveredItems.includes(row.id) : !row.borrado)
    );
  });

  const tiendasColumnsWithEmails = tiendasColumns.map((column) => {
    if (column.field === "administrativos" || column.field === "inspectores") {
      return {
        ...column,
        renderCell: (params) => {
          const ids = params.value || [];
          const emailValues = ids.map((id) => emails[id]);
          return <ArrayCellRenderer values={emailValues} />;
        },
      };
    }
    return column;
  });
  
  const columns = tiendasColumnsWithEmails.concat(actionColumn);
  
  
  return (
    <div className="datatable">
      <div className="datatableTitle datatableTitle-tiendas">
        <div className="headTiendas">
          {clienteImg ? (
            <img src={clienteImg} alt="Logo" className="clienteImg" />
          ) : (
            <StoreMallDirectoryRoundedIcon className="clienteImg" style={{ color: '#fff', fontSize: 40 }} />
          )}
          <h2 className="title-registro-tiendas">
            Servicios de {nombreCliente}
          </h2>
        </div>
        <div className="rightActions">
          <div className="searchContainer">
            <input
              type="text"
              placeholder="Buscar"
              className="searchInput"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <SearchIcon className="searchIcon" /> {/* Ícono de lupa usando MUI */}

          </div>
          <Link
            to={`/clientes/${encodeURIComponent(clienteId)}/newClienteTienda/${encodeURIComponent(nombreCliente)}`}
            className="link link-right"
          >
            Nuevo servicio
          </Link>
        </div>
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={showDeleted}
            onChange={handleShowDeletedChange}
            color="primary"
          />
        }
        label="Mostrar borrados"
      />
      <DataGridPro
        className="datagrid"
        rows={filteredData}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        pageSize={100}
        rowsPerPageOptions={[9]}
        checkboxSelection
        onRowSelectionModelChange={onSelectionModelChange}
        localeText={customLocaleText}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
      />
    </div>
  );
};

export default DatatableTiendasCliente;