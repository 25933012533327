import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link } from 'react-router-dom';
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PersonIcon from '@mui/icons-material/Person';
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import UserDropdown from "../userDropdown/UserDropdown";
import { useSidebarState } from '../sidebar/SidebarStateContext';
import { toggleSidebar } from '../sidebar/sidebarActions';

/**
 * Componente de la barra de navegación superior.
 */
const Navbar = () => {
  const { currentUser, adminData } = useContext(AuthContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { dispatch: sidebarDispatch } = useSidebarState();

  const handleUserClick = () => {
    setIsDropdownOpen(!isDropdownOpen); // Cambia el estado del modal
  };

  const handleToggleSidebar = () => {
    sidebarDispatch(toggleSidebar());
  };

  return (
    <div className="navbar">
      <div className="wrapper">
        {/* Hamburger menu on the left */}
        <div className="item" onClick={handleToggleSidebar}>
          <MenuIcon className="iconMenu" />
        </div>
        <div className="items">
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">0</div>
          </div>
          <div className="item">
            <MailOutlineIcon className="icon" />
            <div className="counter2">0</div>
          </div>
          <div className="item">
            {adminData && adminData.foto ? (
              <img src={adminData.foto} alt="Admin" className="avatar" />
            ) : (
              <PersonIcon className="avatar" />
            )}
            <p className="user" onClick={handleUserClick}>{currentUser.email} &#709;</p>
          </div>
          {isDropdownOpen && <UserDropdown onClose={() => setIsDropdownOpen(false)} />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
