import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import noImage from '../../../images/no-image.png'


export const ImageUploader = ({ file, setFile }) => {
    return (
        <div className="left">
            <img src={file ? URL.createObjectURL(file) : noImage} alt="" style={{ maxWidth: "50%", maxHeight: "50%", alignSelf: "center" }}/>
            <label htmlFor="file">
                <div className='upload-label'>
                    <DriveFolderUploadOutlinedIcon className="icon" /> Subir imagen
                </div>
            </label>
            <input
                type="file"
                id="file"
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: "none" }}
            />
        </div>
    );
};