import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import UpdateBox from "../../components/infoBox/UpdateBox";
import { useEffect, useState, useContext } from "react";
import { collection, query, getDocs, where, doc, getDoc, limit, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import BarChartWidget from '../../components/chart/BarChartWidget';
import { useSidebarState } from "../../components/sidebar/SidebarStateContext";

const Home = () => {
  const [widgetData, setWidgetData] = useState({
    vigilantes: { amount: null, growthPercentage: 0 },
    tiendas: { amount: null, growthPercentage: 0 },
    documents: { amount: null, growthPercentage: 0 },
  });
  const [recentDocuments, setRecentDocuments] = useState([]);
  const [servicioId, setServicioId] = useState(null);

  const { currentUser, adminData } = useContext(AuthContext);
  const { collapsed } = useSidebarState();

  // Agregar una clase basada en el estado de colapso
  const containerClass = collapsed ? "homeContainer collapsed" : "homeContainer";

  const chartData = Object.keys(widgetData).map(key => ({
    name: key,
    amount: widgetData[key].amount,
    growthPercentage: widgetData[key].growthPercentage,
  }));

  useEffect(() => {
    // Aquí, puedes llamar a la función fetchData para cada tipo de widget
    fetchData("vigilantes");
    fetchData("tiendas");
    fetchData("documents");
    // Llama a la función para obtener los documentos recientes
    fetchRecentDocuments();
  }, [currentUser, servicioId]);

  // Función para obtener los documentos recientes
  const fetchRecentDocuments = async () => {
    if (adminData) {

      // Fetch client names from the "clientes" collection
      const clientNames = await Promise.all(adminData.clientes?.map(async clientId => {
        const clientDocRef = doc(db, "servicios", servicioId, "clientes", clientId);
        const clientDocSnapshot = await getDoc(clientDocRef);

        if (clientDocSnapshot.exists()) {
          return clientDocSnapshot.data().nombre; // Assuming there is a field called "nombre" in your "clientes" documents
        }

        return null;
      }));

      const recentDocs = [];

      for (const clientName of clientNames) {
        const documentsRef = collection(db, "servicios", servicioId, "documents");
        const q = query(documentsRef, where("cliente", "==", clientName), orderBy("fecha", "desc"), limit(5));
        const querySnapshot = await getDocs(q);
    
        for (const doc of querySnapshot.docs) {
          const documentData = doc.data();
          const vigilanteId = documentData.vigilante.replace('@gmail.com', '');
    
          const vigilanteQuery = query(collection(db, "servicios", servicioId, "vigilantes"), where("tipVS", "==", vigilanteId));
          const vigilanteSnapshot = await getDocs(vigilanteQuery);
    
          if (!vigilanteSnapshot.empty) {
            const vigilanteData = vigilanteSnapshot.docs[0].data();
            recentDocs.push({ ...documentData, vigilanteData });
          } else {
            recentDocs.push(documentData);
          }
        }
      }
    
      setRecentDocuments(recentDocs);
    }
  };

  const fetchData = async (type) => {

    if (currentUser) {
      const serviciosRef = collection(db, "servicios");
      const q = query(serviciosRef, where("admins", "array-contains", currentUser.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const servicioDoc = querySnapshot.docs[0];
        setServicioId(servicioDoc.id)
      }
    }

    if (servicioId) {
      const monthlyDataRef = doc(db, 'monthlyData', servicioId, type, 'currentMonth');
      const monthlyDataDoc = await getDoc(monthlyDataRef);

      if (monthlyDataDoc.exists) {
        const monthlyData = monthlyDataDoc.data();
        setWidgetData(prevData => ({
          ...prevData,
          [type]: {
            amount: monthlyData.totalAllTime,
            growthPercentage: monthlyData.totalLastMonth,
          }
        }));
      } else {
        console.log("No hay datos mensuales disponibles para este servicio y tipo.");
      }
    }
  };

  function formatDate(timestamp) {
    const date = timestamp.toDate(); // Convierte el timestamp a un objeto Date
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('es-ES', options); // Ajusta el idioma y formato a tus necesidades
  }

  return (
    <div data-testid="sidebar1" className="home">
      <Sidebar />
      <div data-testid="navbar1" className={containerClass}>
        <Navbar />
        <div className="user-greeting">
          <h2> Hola {currentUser?.displayName || currentUser?.email} ! </h2>
        </div>
        <div data-testid="widget" className="widgets">
          <Widget type="vigilantes" data={widgetData.vigilantes} />
          <Widget type="tiendas" data={widgetData.tiendas} />
          <Widget type="documents" data={widgetData.documents} />
          {/* <Widget type="earning" /> */}
        </div>
        <BarChartWidget data={chartData} />

        <div className="box-container">
          <div className="service-info-box recent-documents">
            <h2>Última actividad</h2>
            <ul>
              {recentDocuments.map((doc, index) => (
                <li key={index} className="document-item">
                  <div className="info">
                    <div className="name-text">
                      <br></br>
                      <span className="blue-text">{doc.documentTypeId}</span>
                      <p>{doc.vigilanteData.nombre} {doc.vigilanteData.apellidos}</p>
                    </div>
                    <p className="date-text">{formatDate(doc.fecha)}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <UpdateBox />
        </div>
      </div>
    </div>
  );
};

export default Home;
