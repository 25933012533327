import { Link } from "react-router-dom";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import "./table.scss";
import customLocaleText from '../datatable/LocaleText'


export const FilterBar = ({
    searchTerm,
    handleSearchChange,
    filterDate,
    handleFilterChange,
    filterDateRegistro,
    handleFilterChangeDate,
    searchTienda,
    handleTiendaFilterChange,
    filterVigilante,
    handleVigilanteFilterChange,
}) => (
    <div className="filterBarWrapper">
        <div className="filterBar">
            <input
                type="text"
                placeholder="Filtrar por tipo de reporte"
                value={searchTerm}
                onChange={handleSearchChange}
            />
        </div>
        <div className="filterBar">
            <input
                type="text"
                placeholder="Filtrar por fecha de subida"
                value={filterDate}
                onChange={handleFilterChange}
            />
        </div>
        <div className="filterBar">
            <input
                type="text"
                placeholder="Filtrar por fecha de suceso"
                value={filterDateRegistro}
                onChange={handleFilterChangeDate}
            />
        </div>
        <div className="filterBar">
            <input
                type="text"
                placeholder="Filtrar por servicio"
                value={searchTienda}
                onChange={handleTiendaFilterChange}
            />
        </div>
        <div className="filterBar">
            <input
                type="text"
                placeholder="Filtrar por vigilante"
                value={filterVigilante}
                onChange={handleVigilanteFilterChange}
            />
        </div>
    </div>
);

export const ActionButtons = ({ handleDownloadSelected, selectedRows }) => (
    <div className="tableActions">
        <div className="downloadButton">
            <button onClick={handleDownloadSelected} disabled={selectedRows.length === 0}>
                Descargar seleccionados
            </button>
        </div>
    </div>
);

export const Table = ({ filteredRows, allRowsSelected, selectedRows, handleSelectAllRows, handleRowSelection, handleDeleteSelected }) => (
    <DataGridPro
        initialState={{
            columns: {
                columnVisibilityModel: {
                    id: false,
                },
            },
        }}
        className="table"
        pageSize={100}
        rowsPerPageOptions={[20]}
        slots={{ toolbar: GridToolbar }}
        columns={[
            {
                field: "selection",
                headerName: "check",
                headerClassName: "checkbox-header", // Add a class to style the header checkbox
                width: 50,
                sortable: false,
                renderHeader: (params) => (
                    <Checkbox
                        checked={allRowsSelected}
                        indeterminate={
                            selectedRows.length > 0 && selectedRows.length < filteredRows.length
                        }
                        onChange={handleSelectAllRows}
                    />
                ),
                renderCell: (params) => (
                    <Checkbox
                        checked={selectedRows.includes(params.id)}
                        onChange={(event) => handleRowSelection(event, params.id)}
                    />

                ),
            },
            {
                field: "id", // Agrega la columna de id
                hide: true, // Oculta la columna en la vista
            },
            {
                field: "documentTypeId",
                headerName: "Tipo de reporte",
                flex: 1,
                valueGetter: (params) => {
                    // Campos predefinidos
                    const predefinedIds = ['112', 'denuncia', 'ims', 'intervencion', 'legal', 'parte de trabajo'];

                    // Verifica si el valor de documentTypeId está en tus predefinidos
                    if (predefinedIds.includes(params.row.documentTypeId)) {
                        return params.row.documentTypeId;
                    } else {
                        // Si no es un valor predefinido, muestra documentTypeName
                        return params.row.documentTypeName || 'Sin tipo definido';
                    }
                },
            },
            {
                field: "fecha",
                headerName: "Fecha subida",
                flex: 1,
                valueGetter: (params) => {
                    const fecha = params.row.fecha;
                    if (fecha) {
                        // Parsea la fecha en formato "dd/mm/aaaa"
                        const dateParts = fecha.split("/"); // Divide la fecha en partes: ["20", "7", "2023"]

                        // Añadir un '0' delante de las cifras de un solo dígito
                        dateParts[0] = dateParts[0].length === 1 ? "0" + dateParts[0] : dateParts[0];
                        dateParts[1] = dateParts[1].length === 1 ? "0" + dateParts[1] : dateParts[1];

                        // Devuelve la fecha formateada
                        return dateParts.join("/");
                    }
                    return params.row.fecha;
                },
                sortComparator: (v1, v2) => {
                    // Parsea las fechas en formato "dd/mm/aaaa" a objetos Date para que se puedan comparar adecuadamente
                    const dateParts1 = v1.split("/").reverse().join(""); // Convierte "dd/mm/aaaa" en "aaaammdd"
                    const dateParts2 = v2.split("/").reverse().join("");

                    return dateParts1.localeCompare(dateParts2);
                },
            },
            {
                field: "fields.FECHA",
                headerName: "Fecha de suceso",
                flex: 1,
                valueGetter: (params) => {
                    // Detectar si el navegador es Safari
                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

                    // No ejecutar el valueGetter si es Safari
                    if (isSafari) return params.row.fecha;

                    const fecha = params.row.fields.FECHA;
                    if (fecha) {
                        // Parsea la fecha en formato "aaaa-mm-dd" a un objeto Date
                        const dateParts = fecha.split("-"); // Divide la fecha en partes: ["2023", "07", "20"]
                        const date = new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`);

                        // Formatea la fecha en "dd/mm/aaaa" para mostrarla en la celda
                        const dia = date.getDate();
                        const mes = date.getMonth() + 1;
                        const año = date.getFullYear();
                        const fechaFormateada = `${dia < 10 ? '0' : ''}${dia}/${mes < 10 ? '0' : ''}${mes}/${año}`;

                        return fechaFormateada;
                    } else {
                        return params.row.fecha;
                    }
                },
                sortComparator: (v1, v2) => {
                    // Parsea las fechas en formato "aaaa-mm-dd" a objetos Date para que se puedan comparar adecuadamente
                    const dateParts1 = v1.split("/").reverse().join(""); // Convierte "dd/mm/aaaa" en "aaaammdd"
                    const dateParts2 = v2.split("/").reverse().join("");

                    return dateParts1.localeCompare(dateParts2);
                },
            },
            {
                field: "tipVS",
                headerName: "TIP",
                flex: 1,
                valueGetter: (params) => params.row.vigilante.replace('@gmail.com', ''),
            },
            {
                field: "nombre",
                headerName: "Nombre",
                flex: 1,
            },
            {
                field: "tienda",
                headerName: "Servicio",
                flex: 1,
            },
            {
                field: "cliente",
                headerName: "Cliente",
                flex: 1,
            },
            {
                field: "actions",
                headerName: "Acciones",
                width: 200,
                renderCell: (params) => (
                    <div className="cellAction">
                        <Link to={`/vigilantes/details/${params.id}`}>
                            <div className="viewButton">Ver</div>
                        </Link>
                        <DeleteIcon className="deleteIcon" onClick={handleDeleteSelected} disabled={selectedRows.length === 0} />
                    </div>
                ),
            },
        ]}
        rows={filteredRows}
        autoHeight
        disableSelectionOnClick
        localeText={customLocaleText}
        getRowClassName={(params) => {
            const isDownloaded = params.row.downloaded === true;

            // Devuelve la clase de estilo en función de si downloaded es undefined, false o true
            if (isDownloaded) {
                return 'downloadedRow';
            } else if (params.row.downloaded === false) {
                return 'notDownloadedRow';
            } else {
                return 'notDownloadedRow'; // Otra clase para el caso de downloaded === undefined
            }
        }}
    />
);

export const DateFilterDropdown = ({ dateFilter, setDateFilter }) => (
    <div className="dateFilterDropdown">
        <label className="dateFilter" htmlFor="dateFilter">Mostrar documentos de los últimos:</label>
        <select id="dateFilter" value={dateFilter} onChange={(event) => setDateFilter(event.target.value)}>
            <option value="all">Todos</option>
            <option value="last3Days">Últimos 3 días</option>
            <option value="lastWeek">Última semana</option>
            <option value="today">Hoy</option>
        </select>
    </div>
);