import { useEffect, useReducer, createContext, useRef } from "react";
import AuthReducer from "./AuthReducer";
import getCurrentService from "./GetCurrentService.js"
import { db } from '../firebase';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';

/**
 * Estado inicial del contexto de autenticación.
 * @type {Object}
 * @property {Object|null} currentUser - Usuario actualmente autenticado.
 * @property {Object|null} currentService - Servicio asociado al usuario actual.
 */
const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
  currentService: null,
  adminData: null, // Agrega esto para almacenar la información del administrador
};

export const AuthContext = createContext(INITIAL_STATE);

/**
 * Proveedor de contexto de autenticación.
 * @param {Object} props - Propiedades del componente.
 * @param {Object} props.children - Componentes hijos.
 * @returns {JSX.Element} - Elemento de React que envuelve los componentes hijos con el contexto de autenticación.
 */
export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const { currentUser } = state;

  const isMounted = useRef(true); // Referencia para rastrear el montaje del componente

  useEffect(() => {
    return () => {
      isMounted.current = false; // Se establece a falso cuando el componente se desmonta
    };
  }, []);

  useEffect(() => {
    const fetchCurrentService = async () => {
      if (currentUser) {
        try {
          const currentService = await getCurrentService(currentUser);
          if (isMounted.current) {
            dispatch({ type: "SET_CURRENT_SERVICE", payload: currentService });
          }
        } catch (error) {
          console.error("Error al obtener el servicio actual:", error);
        }
      }
    };

    fetchCurrentService();
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      if (state.currentUser && state.currentService) {
        try {
          const adminQuery = query(
            collection(db, 'servicios', state.currentService.id, 'admins'),
            where('correo', '==', state.currentUser.email),
            limit(1)
          );
          const adminSnapshot = await getDocs(adminQuery);
  
          if (!adminSnapshot.empty) {
            const adminData = adminSnapshot.docs[0].data();
            const adminId = adminSnapshot.docs[0].id; // Obtén el id del documento
  
            if (isMounted.current) {
              // Actualiza el estado con los datos del administrador y el id del documento
              dispatch({ type: "SET_ADMIN_DATA", payload: { ...adminData, id: adminId } });
            }
          }
        } catch (error) {
          console.error('Error al obtener el perfil:', error);
        }
      }
    };
    fetchData();
  }, [state.currentUser, state.currentService]);
  

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{ currentUser: state.currentUser, currentService: state.currentService, adminData: state.adminData, dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};

