const formFields = [
    { label: 'Nombre', name: 'nombre' },
    { label: 'Apellido', name: 'apellido' },
    { label: 'Foto', name: 'foto' },
    { label: 'Tipo Usuario', name: 'tipoUsuario' },
    { label: 'Teléfono', name: 'telefono' },
    { label: 'Teléfono Corto', name: 'telefonoCorto' },
    { label: 'Correo', name: 'correo' },
    { label: 'Localización', name: 'localizacion' },
    { label: 'Puesto', name: 'puesto' },

  ];
  
  export default formFields;